import {
  getActiveUser,
  navigate,
  guardRoute,
  sendGetRequest,
  formatDate,
  getUserStatus,
  sendPatchRequest,
  sendDeleteRequest,
  shortenUrl
} from "../utils/utils";
import DataListWithSearch from "./component/@list/list-search";
import { useState, useEffect } from "react";
import AppRoutes from "../routing/routes";
import { Button, Dialog, DialogActions, DialogContent, Grid, Icon } from "@mui/material";
import { coreStyles } from "../theme/style";

const getTableHead = () => {
  return [
    { id: "createdAt", label: "Created On", alignRight: false },
    { id: "url", label: "URL", alignRight: false },
    { id: "size", label: "Size", alignRight: false },
    { id: "active", label: "Status", alignRight: false },
    { id: "_", label: "", alignRight: false }
  ];
};

const startEdit = (uuid) => navigate(`${ AppRoutes.appSettings }/${AppRoutes.music}${uuid ? `?uuid=${uuid}` : ''}`, true);


const BackgroundMusics = () => {
  guardRoute();
  const loggedUser = getActiveUser();
  const [dataList, setDataList] = useState([]);
  const [loading, onLoading] = useState(false);
  const [audio, setAudio] = useState(null);
  const appStyle = coreStyles()

  const userOptions = [
    {
      icon: "delete",
      value: "delete",
      label: "Delete",
      onClick: (value, selected) => {
        onLoading(true);
        Promise.all(selected.map(uuid => sendDeleteRequest('languages', uuid))).then(response => {
          executeFetch();
        })
      }
    },
    
    {
      icon: "toggle_on",
      value: "activate",
      label: "Activate",
      onClick: (_value, selected) => executeUpdate(true, selected)
    },
    {
      icon: "toggle_off",
      value: "de-activate",
      label: "Deactivate",
      onClick: (_value, selected) => executeUpdate(false, selected),
    },
  ];
  const filterOptions = [];

  const resetAudio = () => setAudio(null);

  const executeFetch = () => {
    sendGetRequest('music?metadata=true', (loading) => onLoading(loading),
      (data) => setDataList(data));
  };


  useEffect(
    () => executeFetch(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loggedUser.email]
  );

  const executeUpdate = (active, uuids) => {
    onLoading(true);
    Promise.all(uuids.map(uuid => sendPatchRequest(
      `music/${uuid}`,
      { active })))
      .then(res => {
        executeFetch()
        onLoading(false);
      });
  };

  return (
    <div>
      <DataListWithSearch
        sectionTitle="Background Music"
        createNewLabel={loggedUser.active ? "Add Music" : null}
        data={dataList}
        primaryKey="uuid"
        filterColumn={"createdOn"}
        emptyIcon="queue_music"
        avatarKey="uuid"
        loading={loading}
        onCreateNewItem={() => startEdit()}
        headers={getTableHead()}
        onBack={() =>
          navigate(`${AppRoutes.appSettings}`, true)
        }
        rowData={(data) => {
          const _entries = [
            { value: formatDate(data.createdAt), width: 200 },
            {
              value: shortenUrl(`${data.url}`),
              width: 160,
            },
            { value: data.name },
            { value: data.size },
            { ...getUserStatus(data.active) },
            {
              value: (<Icon children={"play_circle_filled"} color={"primary"} className={appStyle.asLink} onClick={() => setAudio(data.url)} />),
              align: "center",
            }
          ];
          return {
            exclude: [
              data.active ? "activate" : "deactivate",
            ],
            entries: _entries,
          };
        }}
        selectionOptions={userOptions.filter((option) => option.value !== "edit")}
        filterOptions={filterOptions}
        optionItems={userOptions}
      />

      <Dialog
        open={audio !== null}
        onClose={() => resetAudio()}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogContent>
          <Grid container direction="row" spacing={2} sx={{ px: 4, pt: 6 }}
            justifyContent="center">
            <p align="center" sx={{mt: 3}}>
              <audio controls style={{ width: "400px",marginTop: 20 }}>
              <source src={audio} type="audio/ogg" />
            </audio></p>
            
          </Grid>
        </DialogContent>
        <DialogActions sx={{ pb: 4 }}>
          <Button onClick={() => resetAudio()}>Close</Button>
          
        </DialogActions>
      </Dialog>

    </div>
  );
};

export default BackgroundMusics;