import PropTypes from "prop-types";
import { IconButton, Alert, AlertTitle, Icon } from "@mui/material";

Status.propTypes = {
  error: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  timeout: PropTypes.number,
};

export default function Status({ error, message, onClose, timeout = 3000 }) {
  setTimeout(() => onClose(), timeout);
  return (
    <Alert
      severity={error ? "error" : "success"}
      variant="filled"
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={() => onClose()}
        >
          <Icon children="close" fontSize="inherit" />
        </IconButton>
      }
    >
      <AlertTitle>{error ? "Error" : "Success"}</AlertTitle>
      {message}
    </Alert>
  );
}
