import { Grid } from "@mui/material";
import "react-dropzone-uploader/dist/styles.css";
import {
  getParams,
  navigate,
  guardRoute,
  sendGetRequest,
  sendPostRequest,
  sendPatchRequest,
} from "../utils/utils";
import EditPage from "./component/edit-page";
import { useState, useEffect } from "react";
import AppRoutes from "../routing/routes";
import EasyTextEdit from "./component/text-edit";
import {
  FormControlLabel,
  Switch,
  Typography
} from "@mui/material";
import { coreStyles } from "../theme/style";

const _ref = {
  uuid: "",
  isoCode: "",
  name: "",
  active: null
};


const LanguageEdit = () => {
  guardRoute(false, true);
  const appStyle = coreStyles();
  const { uuid } = getParams();
  var [language, setLanguage] = useState(_ref);

  const [status, setStatus] = useState({
    error: false,
    loading: false,
    message: null,
  });
  useEffect(() => {
     sendGetRequest(`languages?uuid=${uuid}`,
      (loading) => setStatus({ ...{ loading: loading } }), (data) => setLanguage({...data}))
    return () => setLanguage(_ref);
    // eslint-disable-next-line
  }, [uuid]);

  const createUpdate = () => {
    if (!uuid) {
      delete language.uuid;
      sendPostRequest('languages', {...language, active: language.active != null ? language.active: true},
        (loading) => setStatus({ ...{ loading: loading } }), (data) => {
          setStatus({
            ...{
              error: data.error,
              message: data.message,
            },
          });
        });
    } else {
      language.uuid = uuid;
      sendPatchRequest(`languages/${uuid}`, language,
        (loading) => setStatus({ ...{ loading: loading } }), (data) => {
          setStatus({
            ...{
              error: data.error,
              message: data.message,
            },
          });
        });
    }
  };

  const onValueChange = (value, tag) => setLanguage({ ...language, [tag]: value });

  return (
    <EditPage
      title={uuid ? `Edit language` : "Add language"}
      status={status}
      btnLabel={uuid ? "Update Now" : "Add Now"}
      onSave={() => createUpdate()}
      validated={
        language.isoCode &&
        language.name &&
        !status.loading
      }
      onBack={() =>
        navigate(`${AppRoutes.contents}/${AppRoutes.languages}`, true)
      }
      onFinish={() => {
        setStatus({ ...{ loading: false, message: null, error: false } });
        if (!status.error) {
          setLanguage({ ..._ref });
          navigate(`${AppRoutes.contents}/${AppRoutes.languages}`, true)
        }
      }}
    >
      <Grid item xs={4} xl={12}>
        <EasyTextEdit
          label="Iso Code"
          placeholder="International ISO code"
          type="text"
          id="isoCode"
          errorText=""
          textField={true}
          disabled={status.loading}
          value={language.isoCode}
          onChange={onValueChange}
        />
      </Grid>

      <Grid item xs={4} xl={12}>
        <EasyTextEdit
          placeholder="Known language name"
          label="Language name"
          type="text"
          id="name"
          textField={true}
          errorText=""
          disabled={status.loading}
          value={language.name}
          onChange={onValueChange}
        />
      </Grid>

      <Grid item xs={4} xl={12} sx={{mt: 1}}>
        <FormControlLabel
          checked={language.active}
          control={<Switch />}
          onChange={(event) => {
            onValueChange(
              event.target.checked,
              "active"
            );
          }}
          label={
            <Typography variant="body1">Currently Supported </Typography>
          }
        />
      </Grid>

      <Grid item xs={12} xl={12}>
        <p className={appStyle.infoText}>
          * <b><a href={"https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes" }>Get ISO codes from here</a></b>
        </p>
      </Grid>
    </EditPage>
  );
};

export default LanguageEdit;