import { Grid } from "@mui/material";
import "react-dropzone-uploader/dist/styles.css";
import {
  navigate,
  guardRoute,
  sendPostRequest,
  getActiveUser,
} from "../utils/utils";
import EditPage from "./component/edit-page";
import { useState } from "react";
import AppRoutes from "../routing/routes";
import EasyTextEdit from "./component/text-edit";

const _ref = {
  oldPassword: "",
  password: "",
  email:"",
};


const PasswordChange = () => {
  guardRoute(false, true);
  const loggedUser = getActiveUser();
  var [user, setUser] = useState(_ref);
  var [error, setError] = useState(false);

  const [status, setStatus] = useState({
    error: false,
    loading: false,
    message: null,
  });

  const createUpdate = () => {
    sendPostRequest('users/reset-password', {...user, email: loggedUser.email},
        (loading) => setStatus({ ...{ loading: loading } }), (data) => {
          setStatus({
            ...{
              error: data.error,
              message: data.message,
            },
          });
        });
  };


  const onValueChange = (value, tag) => {
    setUser({ ...user, [tag]: value });
    if(tag === "password"){
      setError(`${value}`.length < 6);
    }
  };

  return (
    <EditPage
      title={"Reset password"}
      status={status}
      btnLabel={"Reset Now"}
      onSave={() => createUpdate()}
      validated={!error && user.password && user.oldPassword && !status.loading}
      onFinish={() => {
        setStatus({ ...{ loading: false, message: null, error: false } });
        if (!status.error) {
          setUser({ ..._ref });
          navigate(`${AppRoutes.videos}`, true);
        }
      }}
    >
      <Grid item xs={4} xl={12}>
        <EasyTextEdit
          placeholder="Valid email address"
          label="Email address"
          type="text"
          textField={true}
          id="email"
          errorText={""}
          disabled={true}
          value={loggedUser.email}
          onChange={onValueChange}
        />
      </Grid>
      <Grid item xs={4} xl={12}>
        <EasyTextEdit
          label="Old password"
          placeholder="Previously used password"
          type="password"
          id="oldPassword"
          errorText=""
          password={true}
          disabled={status.loading}
          value={user.oldPassword}
          onChange={onValueChange}
        />
      </Grid>

      <Grid item xs={4} xl={12}>
        <EasyTextEdit
          placeholder="New password"
          label="New password"
          type="password"
          id="password"
          password={true}
          errorText={error ? "Password must be atleast 6 characters":""}
          disabled={status.loading}
          value={user.password}
          onChange={onValueChange}
        />
      </Grid>
    </EditPage>
  );
};

export default PasswordChange;