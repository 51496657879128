import {
  getActiveUser,
  navigate,
  guardRoute,
  sendGetRequest,
  formatDate,
  getUserStatus,
  sendPatchRequest,
  sendDeleteRequest
} from "../utils/utils";
import DataListWithSearch from "./component/@list/list-search";
import { useState, useEffect } from "react";
import AppRoutes from "../routing/routes";

const getTableHead = () => {
  return [
    { id: "createdAt", label: "Created On", alignRight: false },
    { id: "isoCode", label: "ISO Code", alignRight: false },
    { id: "name", label: "Name", alignRight: false },
    { id: "active", label: "Status", alignRight: false }];
};

const startEdit = (uuid) => navigate(`${ AppRoutes.contents }/${AppRoutes.language}${uuid ? `?uuid=${uuid}` : ''}`, true);


const LanguageList = () => {
  guardRoute();
  const loggedUser = getActiveUser();
  const [dataList, setDataList] = useState([]);
  const [loading, onLoading] = useState(false);

  const userOptions = [
    {
      icon: "delete",
      value: "delete",
      label: "Delete",
      onClick: (value, selected) => {
        onLoading(true);
        Promise.all(selected.map(uuid => sendDeleteRequest('languages', uuid))).then(response => {
          executeFetch();
        })
      }
    },
    {
      icon: "edit",
      value: "edit",
      label: "Edit",
      onClick: (_value, selected) => startEdit(selected),
    },
    {
      icon: "toggle_on",
      value: "activate",
      label: "Activate",
      onClick: (_value, selected) => executeUpdate(true, selected)
    },
    {
      icon: "toggle_off",
      value: "de-activate",
      label: "Deactivate",
      onClick: (_value, selected) => executeUpdate(false, selected),
    },
  ];
  const filterOptions = [];

  const executeFetch = () => {
    sendGetRequest('languages?metadata=true', (loading) => onLoading(loading),
      (data) => setDataList(data));
  };


  useEffect(
    () => executeFetch(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loggedUser.email]
  );

  const executeUpdate = (active, uuids) => {
    onLoading(true);
    Promise.all(uuids.map(uuid => sendPatchRequest(
      `languages/${uuid}`,
      { active })))
      .then(res => {
        executeFetch()
        onLoading(false);
      });
  };

  return (
    <div>
      <DataListWithSearch
        sectionTitle="Supported Languages"
        createNewLabel={loggedUser.active ? "Add language" : null}
        data={dataList}
        primaryKey="uuid"
        filterColumn={"name"}
        emptyIcon="language"
        avatarKey="uuid"
        loading={loading}
        onCreateNewItem={() => startEdit()}
        headers={getTableHead()}
        onBack={() =>
          navigate(`${AppRoutes.contents}`, true)
        }
        rowData={(data) => {
          const _entries = [
            { value: formatDate(data.createdAt), width: 200 },
            {
              value: `${data.isoCode}`,
              width: 160,
            },
            { value: data.name },
            { ...getUserStatus(data.active) },
          ];
          return {
            exclude: [
              data.active ? "activate" : "deactivate",
            ],
            entries: _entries,
          };
        }}
        selectionOptions={userOptions.filter((option) => option.value !== "edit")}
        filterOptions={filterOptions}
        optionItems={userOptions}
      />

    </div>
  );
};

export default LanguageList;