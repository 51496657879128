import {
  getActiveUser,
  navigate,
  guardRoute,
  sendGetRequest,
  purpose,
  scheduled,
  UserRoles,
  formatDate,
  sendPatchRequest,
  sendDeleteRequest,
  watchLiveData,
  sendPostRequest,
} from "../utils/utils";
import {
  ButtonGroup,
  Button,
  Icon,
  Box,
  Tooltip,
  DialogContent,
  Dialog,
  DialogActions,
  DialogTitle,
  TextField,
  Autocomplete,
  Checkbox,
  Grid,
  CircularProgress,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  FormControlLabel,
  Switch,
  Typography,
  Chip

} from "@mui/material";
import DataListWithSearch from "./component/@list/list-search";
import { useState, useEffect } from "react";
import AppRoutes from "../routing/routes";
import { coreStyles } from "../theme/style";
import Label from "./component/label";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const getTableHead = () => {
  return [
    { id: "created", label: "Created On", alignRight: false },
    { id: "title", label: "Title", alignRight: false },
    { id: "section", label: "Section", alignRight: false },
    { id: "category", label: "Type", alignRight: false },
    { id: "premium", label: "Premium", alignRight: false },
    { id: "forYoutube", label: "App", alignRight: false },
    { id: "forTheApp", label: "Youtube", alignRight: false },
  ];
};

const ContentList = () => {
  guardRoute();
  const loggedUser = getActiveUser();
  const [dataList, setDataList] = useState([]);
  const [loading, onLoading] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [filterKey, setFilterkey] = useState("title");
  const [logoRef, setLogoRef] = useState({ uuid: "none", withIntroOutro: false });
  const [youtubeProps, setYoutubeProps] = useState({ channel: null, fade: null });
  const [moveProps, setMoveProps] = useState({ isVideo: false, type: null, section: null });
  const [continents, setContinents] = useState([]);
  const [tvShows, setTvShows] = useState([]);
  const [premium, setPremium] = useState([]);
  const [selectedToPremier, setSelectedToPremier] = useState([]);
  const [selectedToYoutube, setSelectedToYoutube] = useState([]);
  const [selectedToStream, setSelectedToStream] = useState([]);
  const [qualities, setQualities] = useState([]);
  const [selectedToBrand, setSelectedToBrand] = useState([]);
  const [selectedToMove, setSelectedToMove] = useState([]);
  const [selectedToLanguage, setSelectedToLanguage] = useState([]);
  var [sections, setSections] = useState([]);
  var [language, setLanguage] = useState(null);
  var [categories, setCategories] = useState([]);
  const appStyle = coreStyles()

  const userOptions = [
    {
      icon: "delete",
      value: "delete",
      label: "Delete",
      onClick: (value, selected) => {
        onLoading(true);
        Promise.all(
          selected.map((uuid) => sendDeleteRequest("contents", uuid))
        ).then((response) => {
          executeFetch();
        });
      },
    },
    {
      icon: "paid",
      value: "premium",
      label: "Premium",
      onClick: (value, selected) => setSelectedToPremier(selected),
    },
    {
      icon: "paid",
      value: "free",
      label: "Free content",
      onClick: (value, selected) => sendPatchRequest(`contents/app-content-premium`,
        { contents: selected, premium: [] },
        (loading) => onLoading(true), (data) => {
          executeFetch();
          onLoading(false)
        }),
    },
    {
      icon: "branding_watermark",
      value: "brand",
      label: "Brand Video",
      onClick: (value, selected) => {
        const _selected = dataList.filter((data) => selected.includes(data.uuid) && data.isVideo === true).map(data => data.uuid);
        setSelectedToBrand(_selected)
      },
    },
    {
      icon: "stream",
      value: "stream",
      label: "Generate streams",
      onClick: (value, selected) => {
        const contents = dataList.filter(data => selected.includes(data.uuid)).map(data => data.uuid);
        setSelectedToStream(contents);
      },
    },
    {
      icon: "edit",
      value: "edit",
      label: "Edit",
      onClick: (_value, selected) => startEdit(selected),
    },
    {
      icon: "output",
      value: "move",
      label: "Move content",
      onClick: (_value, selected) => setSelectedToMove(selected),
    },

    {
      icon: "language",
      value: "language",
      label: "Language",
      onClick: (_value, selected) => setSelectedToLanguage(selected),
    },
    {
      icon: "smart_display",
      value: "preview",
      label: "Preview",
      onClick: (_value, selected) => {
        const content = dataList.filter(data => selected.includes(data.uuid))[0];
        handleClickLink(content.uuid, content.urls.stream ? "stream" : "play")
      },
    },
    {
      icon: "apps",
      value: "app_activate",
      label: "Publish to apps",
      onClick: (_value, selected) => executeUpdate("app_activate", selected),
    },
    {
      icon: "apps_outage",
      value: "app_deactivate",
      label: "Remove from apps",
      onClick: (_value, selected) => executeUpdate("app_deactivate", selected),
    },
    {
      icon: "playlist_add",
      value: "youtube_activate",
      label: "Publish to Youtube",
      onClick: (_value, selected) => {
        const uuids = dataList.filter(data => selected.includes(data.uuid)).filter(data => (data.forYoutube && !data.forYoutube.uploaded) || !data.forYoutube).map(data => data.uuid);
        if (uuids.length === 0) return;
        setSelectedToYoutube(uuids);
      },
    },
  ];

  const startEdit = (uuid) => navigate(`${AppRoutes.contents}/${AppRoutes.content}${uuid ? `?uuid=${uuid}` : ''}`, true);

  const resetPremiumProps = (uuid) => {
    setSelectedToPremier([]);
    setPremium([]);
  }

  const resetStreamProps = (uuid) => {
    setSelectedToStream([]);
    setQualities([]);
  }

  const resetLanguage = () => {
    setLanguage(null);
    setSelectedToLanguage([])
  }

  const resetMoveProps = (uuid) => {
    setSelectedToMove([]);
    setMoveProps({ isVideo: false, type: null, section: null });
  }

  const resetBrandProps = (uuid) => {
    setSelectedToBrand([]);
    setLogoRef("none");
  }

  const resetYoutubeProps = (uuid) => {
    setSelectedToYoutube([]);
    setLogoRef("none");
  }

  const btnGroupOptions = [
    { label: "Content Types", destination: `${AppRoutes.contents}/types` },
    { label: "Content Sections", destination: `${AppRoutes.contents}/sections` },
    { label: "Content Sources", destination: `${AppRoutes.buckets}` },
    { label: "Languages", destination: `${AppRoutes.contents}/${AppRoutes.languages}` },
    { label: "Tasks", destination: `${AppRoutes.tasks}` },
  ];

  const filterOptions = [
    { value: "title", label: "Title", onClick: setFilterkey },
    { value: "category", label: "Category", onClick: setFilterkey },
    { value: "section", label: "Section", onClick: setFilterkey },
  ];

  const executeFetch = (refresh = false) => {
    Promise.all([
      sendGetRequest('contents?metadata=true', (loading) => onLoading(refresh ? false : loading),
        (data) => setDataList(data.map(content => {
          const category = content.section ? content.section.category.name : "N/A";
          return {
            uuid: content.uuid,
            forYoutube: content.forYoutube ? content.forYoutube : null,
            forTheApp: content.forTheApp ? content.forTheApp : false,
            title: content.title,
            url: content.url,
            thumbnail: content.thumbnail,
            category,
            section: content.section ? content.section.title : "N/A",
            created: content.createdAt || new Date().getTime(),
            premium: content.premium && content.premium.length > 0 ? content.premium : null,
            fromAge: content.fromAge,
            toAge: content.toAge,
            streamUrl: content.streamUrl,
            scheduledAt: content.scheduledAt,
            language: content.language,
            isVideo: `${category}`.toLocaleLowerCase() === "video",
            urls: content.urls,
          };
        }))),
      sendGetRequest(`contents/continents`, (loading) => null, (data) => setContinents(data)),
      sendGetRequest(`channels`, (loading) => null, (data) => setTvShows(data)),
      sendGetRequest('categories', (loading) => null, (data) => {
        const types = data.filter(type => type.name !== "Home");
        types.sort((prev, next) => prev.order - next.order);
        setCategories(types);
      }),
      sendGetRequest('sections', (loading) => null, (data) => setSections(data)),
      sendGetRequest(`languages`, (loading) => null, (data) => setLanguages(data))
    ])
  };


  useEffect(() => {
    executeFetch()
    watchLiveData("contents", (data) => executeFetch(true));
    // eslint-disable-next-line
  }, []);

  const handleClickLink = (uuid, type) => {
    navigate(`${AppRoutes.tasks}/${AppRoutes.plays}?uuid=${uuid}&type=${type}&source=${AppRoutes.contents}`, true)
  }

  const executeUpdate = (tag, ids) => {
    let data = {};
    let uuids = [];
    if (`${tag}`.startsWith("app_")) {
      if (tag === "app_activate") {
        uuids = dataList.filter(data => ids.includes(data.uuid)).filter(data => !data.forTheApp).map(data => data.uuid);
      } else {
        uuids = dataList.filter(data => ids.includes(data.uuid)).filter(data => data.forTheApp).map(data => data.uuid);
      }
      data = { contents: uuids, forTheApp: tag === "app_activate" }
      if (uuids.length > 0) {
        onLoading(true);
        sendPatchRequest(`contents/app-content-activation`, data).then(_ => {
          executeFetch()
          onLoading(false);
        })
      }
    }



  };

  return (
    <div>
      <DataListWithSearch
        sectionTitle="Contents"
        createNewElement={
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <ButtonGroup size="large" aria-label="large button group">
              {btnGroupOptions.map((btn) => {
                return (
                  <Button
                    key={btn.destination}
                    onClick={() => navigate(
                      `${btn.destination}`,
                      true
                    )}
                  >
                    {btn.label}
                  </Button>
                );
              })}
            </ButtonGroup>
            <Button
              variant="contained"
              onClick={() => startEdit()}
              className={appStyle.actionButonCustom}
              startIcon={
                <Icon
                  children={"add"}
                  className={appStyle.actionButonIcon}
                />
              }
            >
              {loggedUser.active ? "Add content" : null}
            </Button>
          </Box>
        }
        data={dataList}
        primaryKey="uuid"
        filterColumn={filterKey}
        emptyIcon="toc"
        avatarKey="uuid"
        loading={loading}
        headers={getTableHead()}
        rowData={(data) => {
          const label = purpose(data.premium != null);
          const appStatus = purpose(data.forTheApp);
          const _scheduled = scheduled();
          const _entries = [
            { value: formatDate(data.created) },
            { value: data.title, width: 160 },
            { value: data.section },
            { value: data.category },
            {
              ...(data.premium !== null ? {
                value: (
                  <Tooltip title={data.premium.map(pre => pre.name).join(", ")} arrow>
                    <Button color="success">
                      <Label variant="ghost" color={label.color}>
                        {label.value}
                      </Label>
                    </Button>
                  </Tooltip>)
              } : {
                value: (<Button color="error">
                  <Label variant="ghost" color={label.color}>
                    {label.value}
                  </Label>
                </Button>)
              })
            },
            {
              ...(data.scheduledAt ? {
                value: (
                  <Tooltip title={formatDate(data.scheduledAt, "MMM DD, YYYY hh:mm A")} arrow>
                    <Button color="info">
                      <Label variant="ghost" color={_scheduled.color}>
                        {_scheduled.value}
                      </Label>
                    </Button>
                  </Tooltip>)
              } : {
                value: (<Button color={appStatus.color}>
                  <Label variant="ghost" color={appStatus.color}>
                    {appStatus.value}
                  </Label>
                </Button>)
              })
            },
            { ...purpose(data.forYoutube !== null && data.forYoutube.uploaded) },
            {
              value: (<Icon children={data.streamUrl ? "stream" : "play_circle_filled"} color={data.streamUrl ? "error" : "primary"} className={appStyle.asLink} onClick={() => handleClickLink(data.uuid, data.streamUrl ? "stream" : "play")} />),
              align: "center",
            }
          ];
          return {
            exclude: [
              //data.streamUrl === null && `${data.category}`.toLocaleLowerCase() === "video" ? "" : "stream",
              data.premium === null ? "free" : "premium",
              loggedUser.role === UserRoles.editor ? "delete" : "",
              !data.forTheApp ? "app_deactivate" : "app_activate",
              data.scheduledAt ? "app_deactivate" : "",
              data.isVideo ? "" : "preview",
              !data.isVideo ? "stream" : "",
              data.isVideo && (data.forYoutube === null ||
                (data.forYoutube !== null && data.forYoutube.uploaded === true))
                ? ""
                : "youtube_activate",
              `${data.category}`.toLocaleLowerCase() !== "video" ? "brand" : ""
            ],
            entries: _entries,
          };
        }}
        selectionOptions={userOptions.filter((option) =>
          (option.value !== "edit" && option.value !== "preview") || (loggedUser.role === UserRoles.editor ? option.value !== "delete" : false))}
        filterOptions={filterOptions}
        optionItems={userOptions}
      />

      <Dialog
        open={selectedToPremier.length > 0}
        onClose={() => resetPremiumProps()}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle> Set {selectedToPremier.length} content{selectedToPremier.length > 1 ? "s" : ""} premium locations</DialogTitle>
        <DialogContent>
          <Grid container direction="column" sx={{ pt: 4 }}>
            <Autocomplete
              multiple
              id="premium"
              disableCloseOnSelect
              limitTags={10}
              value={premium.map(pre => pre.name)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
              options={continents.map((option) => option.name)}
              renderInput={(params) => (
                <TextField {...params} label="Premium Content Locations" />
              )}
              onChange={(event, value) => setPremium(continents
                .filter(cont => value.includes(cont.name)))}
            />
          </Grid>
        </DialogContent>
        <DialogActions sx={{ pb: 4 }}>
          <Button onClick={() => resetPremiumProps()}>Cancel</Button>
          <Button disabled={premium.length === 0} onClick={() => sendPatchRequest(`contents/app-content-premium`,
            { contents: selectedToPremier, premium },
            (loading) => onLoading(true), (data) => {
              resetPremiumProps();
              executeFetch();
              onLoading(false);

            })} sx={{ mr: 3, pl: 2, pr: 2 }}>{loading && <CircularProgress color="primary" />}{loading ? "" : "Set premium"}</Button>

        </DialogActions>
      </Dialog>

      <Dialog
        open={selectedToBrand.length > 0}
        onClose={() => resetBrandProps()}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle> Select your TV show</DialogTitle>
        <DialogContent>
          <Grid container direction="column" sx={{ pt: 4 }}>
            <FormControl
              sx={{ minWidth: 50 }} fullWidth>
              <InputLabel id="show">Branding from</InputLabel>
              <Select
                value={logoRef.uuid}
                label="Branding from"
                labelId="show"
                id="logoRef"
                onChange={(e) => setLogoRef({ ...logoRef, uuid: e.target.value })}
              >
                {[{ uuid: "none", name: "No-Branding" }, ...tvShows,].map((tv) => {
                  return (
                    <MenuItem value={tv.uuid} id={`tv-${tv.uuid}`}>
                      &nbsp;&nbsp;&nbsp;{tv.name}
                      <br />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>


            {logoRef.uuid !== "none" && (<FormControlLabel
              sx={{ mt: 4 }}
              labelPlacement="start"
              checked={logoRef.withIntroOutro}
              control={<Switch />}
              onChange={(event) => setLogoRef({ ...logoRef, withIntroOutro: event.target.checked })}
              label={<Typography variant="body1">{logoRef.withIntroOutro ? "With" : "Without "} Intro/Outro included &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Typography>}
            />)}
          </Grid>
        </DialogContent>
        <DialogActions sx={{ pb: 4 }}>
          <Button onClick={() => resetBrandProps()}>Cancel</Button>
          <Button disabled={logoRef.uuid === "none"} onClick={() => {
            sendPostRequest(`contents/brand-content`,
              { contents: selectedToBrand, logoRef: logoRef.uuid, withIntroOutro: logoRef.withIntroOutro },
              (loading) => onLoading(loading), (data) => {
                resetBrandProps();
                executeFetch();
              })
          }} sx={{ mr: 3, pl: 2, pr: 2 }}>{loading && <CircularProgress color="primary" />}{loading ? "" : "Brand Now"}</Button>

        </DialogActions>
      </Dialog>


      <Dialog
        open={selectedToYoutube.length > 0}
        onClose={() => resetBrandProps()}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle> Select channel to upload to</DialogTitle>
        <DialogContent>
          <Grid container direction="row" spacing={2} sx={{ pt: 4 }}>
            <Grid item xs={6} xl={12}>
              <FormControl
                sx={{ minWidth: 50 }} fullWidth>
                <InputLabel id="channel">Upload to</InputLabel>
                <Select
                  value={youtubeProps.channel ? youtubeProps.channel.uuid : null}
                  label="Upload to"
                  labelId="channel"
                  id="channel"
                  onChange={(e) => setYoutubeProps({ ...youtubeProps, channel: tvShows.filter(tv => tv.uuid === e.target.value)[0], })}
                >
                  {[...tvShows].map((tv) => {
                    return (
                      <MenuItem value={tv.uuid} id={`channel-${tv.uuid}`}>
                        &nbsp;&nbsp;&nbsp;{tv.name}
                        <br />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} xl={12}>
              <FormControl
                sx={{ minWidth: 50 }} fullWidth>
                <InputLabel id="fades">Transition Fade</InputLabel>
                <Select
                  value={youtubeProps.fade}
                  label="Transition Fade"
                  labelId="fades"
                  id="fades"
                  onChange={(e) => setYoutubeProps({ ...youtubeProps, fade: e.target.value })
                  }
                >
                  {[true, false].map((fade) => {
                    return (
                      <MenuItem value={fade} id={fade}>
                        &nbsp;&nbsp;&nbsp;{fade ? "White fade" : "Black fade"}
                        <br />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>


        </DialogContent>
        <DialogActions sx={{ pb: 4 }}>
          <Button onClick={() => resetYoutubeProps()}>Cancel</Button>
          <Button disabled={youtubeProps.channel === null || youtubeProps.fade === null} onClick={() => sendPatchRequest(`contents/app-content-youtube`,
            { contents: selectedToYoutube, forYoutube: youtubeProps },
            (loading) => onLoading(true), (data) => {
              resetYoutubeProps();
              executeFetch();
              onLoading(false)
            })} sx={{ mr: 3, pl: 2, pr: 2 }}>{loading && <CircularProgress color="primary" />}{loading ? "" : "Upload Now"}</Button>

        </DialogActions>
      </Dialog>


      <Dialog
        open={selectedToStream.length > 0}
        onClose={() => resetStreamProps()}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle> Generate streams for {selectedToStream.length} content{selectedToPremier.length > 1 ? "s" : ""}</DialogTitle>
        <DialogContent>
          <Grid container direction="column" sx={{ pt: 4 }}>
            <Autocomplete
              multiple
              id="qualities"
              disableCloseOnSelect
              limitTags={4}
              value={qualities}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip variant="filled" label={`${option}p`} {...getTagProps({ index })} />
                ))
              }
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {`${option}p - ${option === "2160" ? "4K" : option === "1080" ? "UHD" : option === "720" ? "HD" : "SD"}`}
                </li>
              )}
              options={["240", "360", "480", "720", "1080", "2160"]}
              renderInput={(params) => (
                <TextField {...params} label={`Streaming quality`} />
              )}
              onChange={(event, value) => setQualities(value)}
            />
          </Grid>
        </DialogContent>
        <DialogActions sx={{ pb: 4 }}>
          <Button onClick={() => resetStreamProps()}>Cancel</Button>
          <Button disabled={qualities.length === 0} onClick={() => {
            sendPostRequest("contents/generate-streams",
              { contents: selectedToStream, streamQuality: qualities },
              (loading) => onLoading(loading)).then((_) => {
                resetStreamProps();
                executeFetch();
              });
          }} sx={{ mr: 3, pl: 2, pr: 2 }}>{loading && <CircularProgress color="primary" />}{loading ? "" : "Generate Now"}</Button>

        </DialogActions>
      </Dialog>


      <Dialog
        open={selectedToMove.length > 0}
        onClose={() => resetMoveProps()}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle> Move to</DialogTitle>
        <DialogContent>
          <Grid container direction="row" spacing={2} sx={{ pt: 4 }}>
            <Grid item xs={6} xl={12}>
              <FormControl sx={{ minWidth: 200 }} fullWidth>
                <InputLabel id="type-label">Content type</InputLabel>
                <Select
                  value={moveProps.type ? moveProps.type.uuid: null}
                  label="Content Type"
                  labelId="type-label"
                  id="category"
                  autoWidth
                  onChange={(e) => setMoveProps({ ...moveProps, type: categories.filter(cat => cat.uuid === e.target.value)[0]})}
                >
                  {(categories || []).map((category) => {
                    return (
                      <MenuItem value={category.uuid} id={category.uuid}>
                        &nbsp;&nbsp;&nbsp;{category.name}
                        <br />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} xl={12}>
              <FormControl
                sx={{ minWidth: 200 }} fullWidth>
                <InputLabel id="section-label">Content Section</InputLabel>
                <Select
                  value={moveProps.section}
                  label="Content Section"
                  labelId="section-label"
                  id="section"
                  onChange={(e) => setMoveProps({ ...moveProps, section: e.target.value })}
                >
                  {(sections || [])
                    .filter((section) => moveProps.type && section.category.uuid === moveProps.type.uuid)
                    .map((section) => {
                      return (
                        <MenuItem value={section.uuid} id={section.uuid}>
                          &nbsp;&nbsp;&nbsp;{section.title}
                          <br />
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>


        </DialogContent>
        <DialogActions sx={{ pb: 4 }}>
          <Button onClick={() => resetMoveProps()}>Cancel</Button>
          <Button disabled={moveProps.type === null || moveProps.section === null} onClick={() => sendPatchRequest(`contents/move-content`,
            { contents: selectedToMove, section: moveProps.section, type: `${moveProps.type.name}`.toLocaleLowerCase(), isVideo: `${moveProps.type.name}`.toLocaleLowerCase() === "video" },
            (loading) => onLoading(true), (data) => {
              resetMoveProps();
              executeFetch();
              onLoading(false)
            })} sx={{ mr: 3, pl: 2, pr: 2 }}>{loading && <CircularProgress color="primary" />}{loading ? "" : "Move Now"}</Button>

        </DialogActions>
      </Dialog>


      <Dialog
        open={selectedToLanguage.length > 0}
        onClose={() => resetLanguage()}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle> Change language to</DialogTitle>
        <DialogContent>
          <Grid container direction="row" spacing={2} sx={{ pt: 4 }}>
            <Grid item xs={12} xl={12}>
              <FormControl
                sx={{ minWidth: 50 }} fullWidth>
                <InputLabel id="language">Content Language</InputLabel>
                <Select
                  value={language}
                  label="Content Language"
                  labelId="language"
                  id="language"
                  onChange={(e) => setLanguage(e.target.value)}
                >
                  {languages.map((language) => {
                    return (
                      <MenuItem value={language.isoCode} id={language.isoCode}>
                        &nbsp;&nbsp;&nbsp;{language.name}
                        <br />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
           
          </Grid>


        </DialogContent>
        <DialogActions sx={{ pb: 4 }}>
          <Button onClick={() => resetLanguage()}>Cancel</Button>
          <Button disabled={language === null} onClick={() => sendPatchRequest(`contents/change-language`,
            { contents: selectedToLanguage, language },
            (loading) => onLoading(true), (data) => {
              resetLanguage();
              executeFetch();
              onLoading(false)
            })} sx={{ mr: 3, pl: 2, pr: 2 }}>{loading && <CircularProgress color="primary" />}{loading ? "" : "Change Now"}</Button>

        </DialogActions>
      </Dialog>


    </div>
  );
};

export default ContentList;