import { createSlice } from "@reduxjs/toolkit";
export const initialState = {
  instance: null,
};
const videoSlice = createSlice({
  name: "videos",
  initialState,
  reducers: {
    setVideos: (state, action) => {
      state.instance = action.payload;
    }
  },
});
export const { setVideos } = videoSlice.actions;
export default videoSlice.reducer;