import PropTypes from "prop-types";
import { useState } from "react";
import { coreStyles } from "../../theme/style";
import {
  InputAdornment,
  Icon,
  FormControl,
  OutlinedInput,
  IconButton,
  FormHelperText,
  TextField,
  InputLabel
} from "@mui/material";

EasyTextEdit.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  password: PropTypes.bool,
  type: PropTypes.string,
  id: PropTypes.string.isRequired,
  multiline: PropTypes.bool,
  textField: PropTypes.bool,
  errorText: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  icon: PropTypes.string,
  endAdornment: PropTypes.node,
  startAdornment: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  sx: PropTypes.object,
};

export default function EasyTextEdit({
  disabled = false,
  error = false,
  type = "text",
  errorText = null,
  placeholder,
  label,
  endAdornment,
  startAdornment,
  multiline = false,
  id,
  password = false,
  onChange,
  icon,
  value = "",
  textField = false,
  readOnly = false,
  sx,
}) {
  const [showPassword, setShowPassword] = useState(false);
  const appStyle = coreStyles();
  const ado = password ? (
    <InputAdornment
      onClick={() => setShowPassword(!showPassword)}
      position="end"
    >
      <IconButton edge="center">
        <Icon
          children={showPassword ? "visibility" : "visibility_off"}
          className={appStyle.input_icons}
        />
      </IconButton>
    </InputAdornment>
  ) : endAdornment;
  return textField ? (
    <TextField
      placeholder={placeholder}
      label={label ? label : placeholder}
      type={password ? (showPassword ? "text" : "password") : type}
      error={error}
      disabled={disabled}
      inputlabelprops={{ shrink: true }}
      className={appStyle.inputs}
      value={value}
      readOnly={readOnly}
      multiline={multiline}
      rows={multiline ? 8 : 1}
      helperText={errorText}
      onChange={(event) => onChange(event.currentTarget.value, id)}
      InputProps={{
        startAdornment: startAdornment ? startAdornment: icon && (
          <InputAdornment position="end">
            <IconButton edge="center">
              <Icon children={icon} className={appStyle.input_icons} />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: ado
      }}
      variant="outlined"
    />
  ) : (
    <FormControl fullWidth sx={{ ...sx }}>
      <InputLabel id={`label-${id}`} shrink={!disabled}>{label}</InputLabel>
      <OutlinedInput
        placeholder={placeholder}
        type={password ? (showPassword ? "text" : "password") : type}
        error={error}
        label={label}
        labelId={`label-${id}`}
        disabled={disabled}
        inputlabelprops={{ shrink: true }}
        className={appStyle.inputs}
        value={value}
        readOnly={readOnly}
        multiline={multiline}
        rows={multiline ? 8 : 1}
        onChange={(event) => onChange(event.currentTarget.value, id)}
        startAdornment={
          startAdornment ? startAdornment: icon && (
            <InputAdornment position="end">
              <IconButton edge="center">
                <Icon children={icon} className={appStyle.input_icons} />
              </IconButton>
            </InputAdornment>
          )
        }
        endAdornment={ado}
      />
      {error && errorText && <FormHelperText error>{errorText}</FormHelperText>}
    </FormControl>
  );
}
