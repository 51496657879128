import { Box, Button, ButtonGroup, Divider, Grid, Typography } from "@mui/material";
import "react-dropzone-uploader/dist/styles.css";
import {
  navigate,
  guardRoute,
  sendGetRequest,
  shortenUrl,
} from "../utils/utils";
import EditPage from "./component/edit-page";
import { useState, useEffect } from "react";
import AppRoutes from "../routing/routes";



const AppSettings = () => {
  guardRoute(false, true);
  let [music, setMusic] = useState({});

  const btnGroupOptions = [
    { label: "Background Music", destination: `${AppRoutes.appSettings}/${AppRoutes.backgroundMusics}` },
    //{ label: "Localization", destination: `${AppRoutes.appSettings}/${AppRoutes.localization}` }
  ];

  const [status, setStatus] = useState({
    error: false,
    loading: false,
    message: null,
  });
  useEffect(() => {
    setStatus({ ...{ loading: true } })
    Promise.all([
        sendGetRequest("music/background"),
      ]).then(response => {
        setStatus({ ...{ loading: false } })
        setMusic(response[0]);
      })
    return () => {};
    // eslint-disable-next-line
  }, []);


  return (
    <EditPage
      title={"App Settings"}
      status={status}
      showBtn={false}
      validated={false}
      createNewElement={
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <ButtonGroup size="large" aria-label="large button group">
            {btnGroupOptions.map((btn) => {
              return (
                <Button
                  key={btn.destination}
                  onClick={() => navigate(
                    `${btn.destination}`,
                    true
                  )}
                >
                  {btn.label}
                </Button>
              );
            })}
          </ButtonGroup>
          
        </Box>
      }
      onFinish={() => {
        setStatus({ ...{ loading: false, message: null, error: false } });
        if (!status.error) {
          navigate(`${AppRoutes.payments}`, true)
        }
      }}
    >
      {music && (<Grid container sx={{ px: 5 }}>
        <Grid item xs={12} xl={12}>
          <Typography variant="h6" component="h6" color="text.disabled">
            App's Background Music
          </Typography>
          <Divider sx={{mt: 2}}></Divider>
        </Grid>

        {!music.url && (<Grid item xs={12} sx={{mt: 4}}>
          <Typography variant="body1" component="h6">
            Add background background music to your app
          </Typography>
        </Grid>)}

       <Grid container direction="row" alignContent={"space-between"} justifyContent={"space-between"}>
          {music.url && (<Grid item md={7} xs={12} sx={{ pt: 5 }}>
            <Typography variant="body1" component="a" >
              {shortenUrl(music.url)}
            </Typography>
          </Grid>)}

          {music.url && (<Grid item md={4} xs={12} sx={{ pt: 4 }}>
            <audio controls sx={{ width: "100px !important" }}>
              <source src={music.url} type="audio/ogg" />
            </audio>
          </Grid>)}
       </Grid>
      </Grid>)}


    </EditPage>
  );
};

export default AppSettings;