import {
  getActiveUser,
  navigate,
  guardRoute,
  sendGetRequest,
  getUserStatus,
  UserRoles,
  formatDate,
  sendPatchRequest,
  sendDeleteRequest,
} from "../utils/utils";
import DataListWithSearch from "./component/@list/list-search";
import { useState, useEffect } from "react";
import AppRoutes from "../routing/routes";

const getTableHead = () => {
  return [
    { id: "created", label: "Created On", alignRight: false },
    { id: "name", label: "Title", alignRight: false },
    { id: "order", label: "Order", alignRight: false },
    { id: "activeIcon", label: "Active Icon", alignRight: false },
    { id: "inactiveIcon", label: "Inactive Icon", alignRight: false },
    { id: "state", label: "State", alignRight: false },
  ];
};

const ContentTypeList = () => {
  guardRoute();
  const loggedUser = getActiveUser();
  const [dataList, setDataList] = useState([]);
  const [loading, onLoading] = useState(false);
  const [filterKey, setFilterkey] = useState("name");

  const userOptions = [
    {
      icon: "delete",
      value: "delete",
      label: "Delete",
      onClick: (value, selected) =>{
         onLoading(true);
        Promise.all(selected.map(uuid => sendDeleteRequest('categories', uuid))).then(response => {
          executeFetch();
        })
      }
       
    },
    {
      icon: "edit",
      value: "edit",
      label: "Edit",
      onClick: (_value, selected) => {
        const home = dataList.filter(data => selected.length === 1 && data.name.toLowerCase() === "home" && selected.includes(data.uuid));
        if(home.length > 0){
          navigate(`${AppRoutes.contents}/${AppRoutes.featured}`, true)
        }else {
          startEdit(selected.join(""))
        }
      }
    },
    {
      icon: "toggle_on",
      value: "activate",
      label: "Activate",
      onClick: (_value, selected) => executeUpdate(true, selected)
    },
    {
      icon: "toggle_off",
      value: "deactivate",
      label: "Deactivate",
      onClick: (_value, selected) => executeUpdate(false, selected),
    },
  ];


  const filterOptions = [
    { value: "name", label: "Title", onClick: setFilterkey }
  ];

  const executeFetch = () => {
    sendGetRequest('categories?metadata=true', (loading) => onLoading(loading), (data) => setDataList(data));
  };

  const startEdit = (uuid) => navigate(`${AppRoutes.contents}/${AppRoutes.type}${uuid ? `?uuid=${uuid}`:''}`,true);


  useEffect(() => executeFetch(), []);

  const executeUpdate = (active, ids) => {
    onLoading(true);
    Promise.all(ids.map(id => sendPatchRequest(
      `categories/${id}`,
      { active })))
      .then(res => {
        executeFetch()
        onLoading(false);
      });
  };

  return (
    <div>
      <DataListWithSearch
        sectionTitle="Content types"
        data={dataList}
        createNewLabel={"Add content type"}
        primaryKey="uuid"
        filterColumn={filterKey}
        emptyIcon="format_list_bulleted"
        avatarKey="uuid"
        loading={loading}
        onCreateNewItem={() => startEdit()}
        headers={getTableHead()}
        onBack={() => navigate(`${AppRoutes.contents}`, true)}
        rowData={(data) => {
          const _entries = [
            { value: formatDate(data.createdAt) },
            { value: data.name, width: 160 },
            { value: data.order },
            { value: data.activeIcon || "N/A" },
            { value: data.inactiveIcon || "N/A" },
            { ...getUserStatus(data.active || false) },
          ];
          return {
            exclude: [
              data.name.toLowerCase() === "home" ? "delete":"",
              loggedUser.role === UserRoles.editor ? "delete" : "",
              data.active ? "activate" : "deactivate",
              data.name.toLowerCase() === "home" ? "deactivate":"",
            ],
            entries: _entries,
          };
        }}
        selectionOptions={userOptions.filter(
          (option) =>
            option.value !== "edit" ||
            (loggedUser.role === UserRoles.editor
              ? option.value !== "delete"
              : false)
        )}
        filterOptions={filterOptions}
        optionItems={userOptions}
      />
    </div>
  );
};

export default ContentTypeList;