import { Grid } from "@mui/material";
import "react-dropzone-uploader/dist/styles.css";
import {
  navigate,
  guardRoute,
  sendGetRequest,
  sendPostRequest,
  sendPatchRequest,
} from "../utils/utils";
import EditPage from "./component/edit-page";
import { useState, useEffect } from "react";
import AppRoutes from "../routing/routes";
import EasyTextEdit from "./component/text-edit";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel
} from "@mui/material";

const _ref = {
  uuid: "",
  trialDuration: 1,
};


const FreeTrialEdit = () => {
  guardRoute(false, true);
  let [trial, setTrial] = useState(_ref);

  const [status, setStatus] = useState({
    error: false,
    loading: false,
    message: null,
  });
  useEffect(() => {
    sendGetRequest(`settings`,
       (loading) => setStatus({ ...{ loading: loading } }), (data) => setTrial({...data}))
    return () => setTrial(_ref);
    // eslint-disable-next-line
  }, []);

  const createUpdate = () => {
    if (!trial.uuid.length) {
      delete trial.uuid;
      sendPostRequest('settings', {...trial},
        (loading) => setStatus({ ...{ loading: loading } }), (data) => {
          setStatus({
            ...{
              error: data.error,
              message: data.message,
            },
          });
        });
    } else {
      sendPatchRequest(`settings/${trial.uuid}`, trial,
        (loading) => setStatus({ ...{ loading: loading } }), (data) => {
          setStatus({
            ...{
              error: data.error,
              message: data.message,
            },
          });
        });
    }
  };

  const onValueChange = (value, tag) => setTrial({ ...trial, [tag]: value });

  return (
    <EditPage
      title={"Trial Subscription"}
      status={status}
      btnLabel={"Update Now" }
      onSave={() => createUpdate()}
      validated={
        trial.trialDuration &&
        !status.loading
      }
      onBack={() =>
        navigate(`${AppRoutes.payments}`, true)
      }
      onFinish={() => {
        setStatus({ ...{ loading: false, message: null, error: false } });
        if (!status.error) {
          setTrial({ ..._ref });
          navigate(`${AppRoutes.payments}`, true)
        }
      }}
    >
      <Grid item xs={8} xl={12}>
        <EasyTextEdit
          label="Description"
          placeholder="Description"
          type="text"
          id="description"
          errorText=""
          textField={true}
          disabled={true}
          value={"Unlocks access to all premium content for a specified trial duration"}
          onChange={onValueChange}
        />
      </Grid>

      <Grid item xs={4} xl={12}>
        <FormControl sx={{ minWidth: 50 }} fullWidth>
          <InputLabel id="duration">Trial Duration</InputLabel>
          <Select
            value={trial.trialDuration}
            label="Trial Duration"
            labelId="duration"
            id="trialDuration"
            onChange={(e) => onValueChange(e.target.value, "trialDuration")}
          >
            {[
              { name: "One Day", value: 1 },
              { name: "Three Days", value: 3 },
              { name: "One Week", value: 7 },
            ].map((souces) => {
              return (
                <MenuItem value={souces.value}>
                  &nbsp;&nbsp;&nbsp;{souces.name}
                  <br />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>

    </EditPage>
  );
};

export default FreeTrialEdit;