import { useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Icon,
} from "@mui/material";

MoreMenu.propTypes = {
  options: PropTypes.array,
  selected: PropTypes.array,
  onItemSelected: PropTypes.func,
  icon: PropTypes.string,
  sx: PropTypes.object,
};

export default function MoreMenu({ options, icon, selected, onItemSelected = () => {} , sx = {}}) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton sx={{ ...sx }} ref={ref} onClick={() => setIsOpen(true)}>
        <Icon children={icon || "more_vert"} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 210, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {options.map((item, index) => {
          const { icon, label, value, onClick } = item;
          const _color = !(selected || []).includes(value) ? "text.primary" : "primary.dark";
          const _colorIcon = !(selected || []).includes(value)
            ? "text.primary"
            : "primary";
          return (
            <MenuItem
              onClick={() => {
                setIsOpen(false);
                onItemSelected();
                onClick(value, selected);
              }}
              key={index}
              sx={{ color: _color }}
            >
              {icon && (
                <ListItemIcon>
                  <Icon
                    color={_colorIcon}
                    children={icon}
                    width={24}
                    height={24}
                  />
                </ListItemIcon>
              )}
              <ListItemText
                primary={label}
                primaryTypographyProps={{ variant: "body1" }}
              />
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}
