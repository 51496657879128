import PropTypes from "prop-types";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Box, Drawer, Typography } from "@mui/material";
import useResponsive from "../../../utils/hook";
import Scrollbar from "../scrollbar";
import NavSection from "./navsection";
import navConfig from "../../../routing/navs";
import { getActiveUser, UserRoles } from "../../../utils/utils";
import Logo from "../logo";

const DRAWER_WIDTH = 280;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const { role } = getActiveUser();
  var filter = [];
  if(role === UserRoles.editor) {
    filter = filter.concat(["Users"]);
  }

  const isDesktop = useResponsive("up", "lg");

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box
        sx={{
          mb: 2,
          px: 5,
          py: 5,
          display: "inline-flex",
          background: "#04297A",
          width: "100%",
        }}
      >
        <Logo width={180} height={50} image="logo-white.png" />
      </Box>

      <NavSection
        navConfig={navConfig.filter((nav) => filter.indexOf(nav.title) === -1)}
      />
    </Scrollbar>
  );

  const versionTxt = (
    <Typography variant="body2" align="center" mb={3} color="text.disabled">
      Dashboard  v0.1.0
    </Typography>
  )

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
          {versionTxt}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "background.default",
              borderRightStyle: "dashed",
            },
          }}
        >
          {renderContent}
          {versionTxt}
        </Drawer>
      )}
    </RootStyle>
  );
}
