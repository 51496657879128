import { Grid } from "@mui/material";
import "react-dropzone-uploader/dist/styles.css";
import {
  getParams,
  navigate,
  guardRoute,
  sendGetRequest,
  sendPostRequest,
  sendPatchRequest,
} from "../utils/utils";
import EditPage from "./component/edit-page";
import { useState, useEffect } from "react";
import AppRoutes from "../routing/routes";
import EasyTextEdit from "./component/text-edit";
import {
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button
} from "@mui/material";
import { coreStyles } from "../theme/style";

const _ref = {
  uuid: "",
  firstName: "",
  lastName: "",
  role: "",
  email: "",
  active: true
};


const UserEdit = () => {
  guardRoute(false, true);
  const appStyle = coreStyles();
  const { uuid } = getParams();
  var [user, setUser] = useState(_ref);
  var [auths, setAuths] = useState(null);
  var [emailError, setEmailError] = useState(false);

  const [status, setStatus] = useState({
    error: false,
    loading: false,
    message: null,
  });
  useEffect(() => {
    sendGetRequest(`users?uuid=${uuid}`,
      (loading) => setStatus({ ...{ loading: loading } }), (data) => setUser({ ...data }))
    return () => setUser(_ref);
    // eslint-disable-next-line
  }, [uuid]);

  const createUpdate = () => {
    if (!uuid) {
      delete user.uuid;
      sendPostRequest('users', { ...user, active: true },
        (loading) => setStatus({ ...{ loading: loading } }), (data) => {
          if (!data.error) setAuths(data.auths);
          setStatus({
            ...{
              error: data.error,
              message: data.message,
            },
          });
        });
    } else {
      user.uuid = uuid;
      sendPatchRequest(`users/${uuid}`, user,
        (loading) => setStatus({ ...{ loading: loading } }), (data) => {
          setStatus({
            ...{
              error: data.error,
              message: data.message,
            },
          });
        });
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const onValueChange = (value, tag) => {
    setUser({ ...user, [tag]: value });
    if (tag === "email") {
      setEmailError(!validateEmail(value));
    }
  };

  const onClose = () => {
    setAuths(null);
    navigate(`${AppRoutes.users}`, true);
  }

  return (
    <EditPage
      title={uuid ? `Edit managarial user` : "Add managarial user"}
      status={status}
      btnLabel={uuid ? "Update Now" : "Add Now"}
      onSave={() => createUpdate()}
      validated={
        !emailError &&
        user.firstName &&
        user.lastName &&
        user.email &&
        user.role &&
        !status.loading
      }
      onFinish={() => {
        setStatus({ ...{ loading: false, message: null, error: false } });
        if (!status.error) {
          setUser({ ..._ref });
        }
      }}
    >
      <Grid item xs={3} xl={12}>
        <EasyTextEdit
          label="First name"
          placeholder="Given user name"
          type="text"
          id="firstName"
          errorText=""
          textField={true}
          disabled={status.loading}
          value={user.firstName}
          onChange={onValueChange}
        />
      </Grid>

      <Grid item xs={3} xl={12}>
        <EasyTextEdit
          placeholder="Sir name"
          label="Last name"
          type="text"
          id="lastName"
          textField={true}
          errorText=""
          disabled={status.loading}
          value={user.lastName}
          onChange={onValueChange}
        />
      </Grid>

      <Grid item xs={4} xl={12}>
        <EasyTextEdit
          placeholder="Valid email address"
          label="Email address"
          type="text"
          textField={true}
          error={emailError}
          id="email"
          errorText={emailError ? "Incorrect email address format" : ""}
          disabled={status.loading}
          value={user.email}
          onChange={onValueChange}
        />
      </Grid>
      <Grid item xs={2} xl={12}>
        <FormControl sx={{ minWidth: 100 }} fullWidth>
          <InputLabel id="role-label">Role</InputLabel>
          <Select
            value={user.role}
            label="Role"
            labelId="role-label"
            id="role"
            autoWidth
            onChange={(e) => {
              if (user.role !== e.target.value) {
                onValueChange(e.target.value, "role");
              }
            }}
          >
            {[
              { value: 1, label: "Adminstrator" },
              { value: 2, label: "Editor" },
            ].map((role) => {
              return (
                <MenuItem value={role.value}>
                  &nbsp;&nbsp;&nbsp;{role.label}
                  <br />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} xl={12}>
        <p className={appStyle.infoText}>
          * <b>Adminstrator</b> can create, view, update and delete anything
          within the system
        </p>
        <p className={appStyle.infoText}>
          * <b>Editor</b> can only create, view and update but can't delete
          anything
        </p>

        <Dialog
          open={auths !== null}
          fullWidth
          maxWidth={"sm"}
        >
          <DialogTitle> User login credentials</DialogTitle>
          <DialogContent>
            <Grid container direction="column">
              <p className={appStyle.authText}>
                Email: <b>{auths ? auths.email : ""}</b>
              </p>
              <p className={appStyle.authText}>
                Password: <b>{auths ? auths.password : ""}</b>
              </p>

              <p className={appStyle.highlightText}>
               Carefully copy these details and share to your user.
              </p>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ pb: 4 }}>
            <Button onClick={() => onClose()}>Close</Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </EditPage>
  );
};

export default UserEdit;