import {
  MenuItem,
  Icon,
  IconButton,
  TableRow,
  TextField,
  TableBody,
  TableCell,
  Grid,
  Tooltip,
} from "@mui/material";
import "react-dropzone-uploader/dist/styles.css";
import { coreStyles } from "../theme/style";
import EasyTextEdit from "./component/text-edit";
import { guardRoute, subscribe, getVideos, navigate } from "../utils/utils";
import EditPage from "./component/edit-page";
import { useState, useEffect } from "react";
import AppRoutes from "../routing/routes";
import { showSnack } from "../utils/utils";
import {arrayMoveImmutable} from 'array-move';

const videoRef = {
  video_name: "",
  video_description: "",
  task_fade: "",
  video_channel: "",
  video_thumb: "",
};

const CombineVideosTaskAdd = () => {
  const appStyle = coreStyles();
  guardRoute(false, true);
  const [videos, setVideos] = useState([]);
  const [video, setVideo] = useState(videoRef);
  const [channels, setChannels] = useState([]);

  const onChange = (val, tag) => {
   var taskVideo = video;
   if(tag === "video_channel"){
    const description = channels.filter(chan => chan._id === val)[0].channel_video_desc;
    taskVideo = {...taskVideo, video_description: description};
   }
   setVideo({...taskVideo, [tag]: val});
  };

  const [status, setStatus] = useState({
    error: false,
    loading: false,
    message: null,
  });

  const convertImageToBlob = (event, blobFile) => {
    let reader = new FileReader();
    reader.onload = (e) => blobFile(e.target.result);
    reader.readAsDataURL(event.target.files[0]);
  };

  const processSelectedThumb = async (event) =>
    convertImageToBlob(event, (file) =>
      onChange(file, "video_thumb")
    );
    

  const createUploadingTask = () => {
    const payload = [videos.map(_video => {
        return {..._video, ...video, video_key: _video.video_key};
      })];
    subscribe(
      "task/createTask",payload,
      (_, result) => {
        if (result) setStatus(result);
      },
      (loading) => setStatus({ ...status, loading: loading })
    );
  };

  useEffect(() => {
    Promise.all([
      subscribe(
        "task/findChannels?active=true",
        null,
        (_, result) => {
          if (result) setChannels(result);
        },
        (loading) => {}
      ),
    ]).then((response) => {});
    const _videos = getVideos() || [];
    if (_videos.length === 0) {
      navigate(`${AppRoutes.videos}`, true);
    } else {
      setVideo(_videos[0])
      setVideos(_videos);
    }
    return () => {};
    // eslint-disable-next-line
  }, []);

  const handleMoveItem = (index, up) => {
    const videoList = arrayMoveImmutable(videos, index, index + (up ? -1: 1));
    console.log(videoList.map(vid => vid.video_name));
    setVideos(videoList);
  }

  return (
    videos.length > 0 && (
      <EditPage
        title="Prepare videos "
        btnLabel="Create Task"
        status={status}
        onSave={() => createUploadingTask()}
        validated={
          video.video_channel !== null &&
          video.video_name !== null &&
          !status.loading
        }
        onFinish={() => {
          setStatus({ ...{ loading: false, message: null, error: false } });
          if (!status.error) {
            navigate(`${AppRoutes.tasks}`, true);
          }
        }}
      >
        <Grid item xs={1}>
          <input
            style={{ display: "none" }}
            id="video_thumb"
            accept="image/jpg,image/jpeg,image/png"
            onChange={(event) => processSelectedThumb(event)}
            type="file"
          />

          <Tooltip
            title={
              video.video_thumb ? (
                <>
                  <img
                    alt=""
                    src={video.video_thumb}
                    className={appStyle.preview_image}
                  />
                  <IconButton
                    aria-label="delete"
                    className={appStyle.preview_margin}
                    onClick={() => onChange(null, "video_thumb")}
                  >
                    <Icon
                      children={"delete"}
                      className={appStyle.input_icons}
                    />
                  </IconButton>
                </>
              ) : (
                "No preview, click to upload"
              )
            }
          >
            <IconButton
              onClick={() => {
                document.getElementById(`video_thumb`).click();
              }}
            >
              <Icon
                children={"add_photo_alternate"}
                color={video.video_thumb ? "success" : "inherit"}
              />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={3} xl={12}>
          <EasyTextEdit
            placeholder="Video Name"
            type="text"
            id="video_name"
            errorText=""
            textField={true}
            disabled={status.loading}
            value={video.video_name}
            onChange={(val, tag) => onChange(val, tag)}
          />
        </Grid>

        <Grid item xs={4} xl={12}>
          <EasyTextEdit
            placeholder="Video Description"
            type="text"
            id="video_description"
            errorText=""
            textField={true}
            disabled={status.loading}
            value={video.video_description}
            onChange={(val, tag) => onChange(val, tag)}
          />
        </Grid>

        <Grid item xs={2} xl={12}>
          <TextField
            select
            fullWidth
            label="Channel"
            value={video.video_channel}
            id="video_channel"
            onChange={(event) => onChange(event.target.value, "video_channel")}
          >
            {channels.map((option) => {
              return (
                <MenuItem value={option._id} className={appStyle.inputs}>
                  &nbsp;&nbsp;&nbsp;{option.channel_name}
                  <br />
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>

        <Grid item xs={2} xl={12}>
          <TextField
            select
            label="Fade"
            value={video.task_fade}
            id="task_fade"
            onChange={(event) => onChange(event.target.value, "task_fade")}
          >
            {[true, false].map((option) => {
              return (
                <MenuItem value={option} className={appStyle.inputs}>
                  &nbsp;&nbsp;&nbsp;{option ? "White Fade" : "Black Fade"}
                  <br />
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <TableBody>
            {videos.map((video, index) => (
              <TableRow>
                <TableCell sx={{ width: "3%" }}>
                  {index !== 0 && (<IconButton onClick={() => handleMoveItem(index, true)}><Icon children={"keyboard_arrow_up"} /></IconButton>)}
                  {index !== videos.length -1 && (<IconButton onClick={() => handleMoveItem(index, false)}><Icon children={"keyboard_arrow_down"} /></IconButton>)}
                </TableCell>
                <TableCell sx={{ width: "30%" }}>
                  {video.video_last_modified}
                </TableCell>
                <TableCell sx={{ width: "30%" }}>{video.video_name}</TableCell>
                <TableCell sx={{ width: "25%" }}>
                  {video.video_bucket}
                </TableCell>
                <TableCell sx={{ width: "20%" }}>{video.video_size}</TableCell>
                <TableCell sx={{ width: "10%" }}>
                  <IconButton
                    onClick={() => {
                      if (videos.length <= 2) {
                        showSnack(
                          "Video merge task require two or more videos"
                        );
                      } else {
                        const _rows = videos.filter(
                          (_video) => _video.video_key !== video.video_key
                        );
                        setVideos(_rows);
                      }
                    }}
                  >
                    <Icon children={"delete"} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Grid>
      </EditPage>
    )
  );
};

export default CombineVideosTaskAdd;
