import { Grid } from "@mui/material";
import "react-dropzone-uploader/dist/styles.css";
import {
  getParams,
  navigate,
  guardRoute,
  sendPatchRequest,
  sendPostRequest,
  sendGetRequest
} from "../utils/utils";
import EditPage from "./component/edit-page";
import { useState, useEffect } from "react";
import AppRoutes from "../routing/routes";
import EasyTextEdit from "./component/text-edit";

const bucketRef = {
  name: "",
  label:"",
  region: "",
  cloudFrontUrl:"",
  inuse: true
};

const BucketEdit = () => {
  guardRoute(false, true);
  const { uuid } = getParams();
  var [bucket, setBucket] = useState(bucketRef);

  const [status, setStatus] = useState({
    error: false,
    loading: false,
    message: null,
  });
  useEffect(() => {
       sendGetRequest(`buckets?uuid=${uuid}`,
      (loading) => setStatus({ ...{ loading: loading } }),
      (data) => {
        if (data) {
          setBucket({ ...bucketRef, ...data });
        }
      })
    return () => setBucket(bucketRef);
    // eslint-disable-next-line
  }, [uuid]);

  const createUpdateBucket = () => {
    if (uuid) {
      sendPatchRequest(`buckets/${uuid}`, {...bucket, uuid},
        (loading) => setStatus({ ...{ loading: loading } }), (data) => {
          setStatus({
            ...{
              error: data.error,
              message: data.message,
            },
          });
        })
    } else {
      sendPostRequest('buckets', bucket,
        (loading) => setStatus({ ...{ loading: loading } }), (data) => {
          setStatus({
            ...{
              error: data.error,
              message: data.message,
            },
          });
        })
    }
  };

  const onValueChange = (value, tag) => {
    setBucket({ ...bucket, [tag]: value });
  };

  return (
    <EditPage
      title={uuid ? "Edit Source" : "Add Source"}
      status={status}
      btnLabel={uuid ? "Update Now" : "Add Now"}
      onSave={() => createUpdateBucket()}
      onBack={() =>navigate(`${AppRoutes.buckets}`, true)}
      validated={
        bucket.name &&
        bucket.label &&
        bucket.region &&
        bucket.cloudFrontUrl && 
        !status.loading
      }
      onFinish={() => {
        setStatus({ ...{ loading: false, message: null, error: false } });
        if (!status.error) {
          setBucket({ ...bucketRef });
          navigate(`${AppRoutes.buckets}`, true);
        }
      }}
    >
      <Grid item xs={3} xl={12}>
        <EasyTextEdit
          label="Bucket label"
          placeholder="A label to remember for"
          type="text"
          id="label"
          errorText=""
          textField={true}
          disabled={status.loading}
          value={bucket.label}
          onChange={onValueChange}
        />
      </Grid>

      <Grid item xs={3} xl={12}>
        <EasyTextEdit
          label="Bucket name"
          placeholder="Bucket name as in AWS"
          type="text"
          id="name"
          errorText=""
          textField={true}
          disabled={status.loading}
          value={bucket.name}
          onChange={onValueChange}
        />
      </Grid>

      <Grid item xs={2} xl={12}>
        <EasyTextEdit
          placeholder="i.e us-west-1"
          label="Bucket Region"
          type="text"
          id="region"
          textField={true}
          errorText=""
          disabled={status.loading}
          value={bucket.region}
          onChange={onValueChange}
        />
      </Grid>

      <Grid item xs={4} xl={12}>
        <EasyTextEdit
          placeholder="Cloud front URL"
          label="Bucket URL"
          type="text"
          id="cloudFrontUrl"
          textField={true}
          errorText=""
          disabled={status.loading}
          value={bucket.cloudFrontUrl}
          onChange={onValueChange}
        />
      </Grid>

    </EditPage>
  );
};

export default BucketEdit;