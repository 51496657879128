import {
  guardRoute,
  SetVideos,
  navigate,
  watchLiveData,
  getStatus,
  sendGetRequest,
  formatDate,
  sendDeleteRequest
} from "../utils/utils";
import DataList from "./component/@list/list";
import { useState, useEffect } from "react";
import AppRoutes from "../routing/routes";

const getTableHead = () => {
  return [
    { id: "createdAt", label: "Added on", alignRight: false },
     { id: "name", label: "Channel Name", alignRight: false },
    { id: "code", label: "Code", alignRight: false },
    { id: "expires", label: "Auth Expires On", alignRight: false },
    { id: "auth", label: "Auth Status", alignRight: false },
    { id: "_", alignRight: false },
  ];
};

const ChannelList = () => {
  guardRoute(false, true);
  const [dataList, setDataList] = useState([]);
  const [loading, onLoading] = useState(false);

  const onAuthClicked = (value, selected) => {
    sendGetRequest(`channels/${selected.join()}/auth`,
      (loading) => onLoading(loading),
      (data) => {
        if(data && data.url){
          window.location.replace(data.url);
        }
      })
  }

  const actionOptions = [
    {
      icon: "delete",
      value: "delete",
      label: "Delete",
      onClick: (value, selected) =>{
        onLoading(true);
        Promise.all(selected.map(uuid => sendDeleteRequest('channels', uuid))).then(response => {
          executeFetch();
        })
      }
        
    },
    {
      icon: "verified_user",
      value: "auth",
      label: "Authenticate",
      onClick: onAuthClicked,
    },
    {
      icon: "lock_reset",
      value: "re-auth",
      label: "Re-Authenticate",
      onClick: onAuthClicked,
    },
    {
      icon: "edit_note",
      value: "edit",
      label: "Edit",
      onClick: (_value, selected) =>
        navigate(
          `${AppRoutes.channels}/${AppRoutes.channel}?uuid=${selected.join("")}`,
          true
        ),
    },
  ];
  const filterOptions = [];

  const executeFetch = (refresh = false) => {

    sendGetRequest(`channels`,
      (loading) => onLoading(refresh ? false : loading),
      (data) => {
        if (data) {
          setDataList(data.map(dat => {
            return {
              ...dat,
              status: dat.auth && dat.token
                ? (dat.expired
                  ? "Expired"
                  : "Authenticated")
                : "Needs Auth",
            };
          }));
        }
      })
  };

  useEffect(() => {
    SetVideos([]);
    executeFetch();
    watchLiveData("channel", (data) => {
      executeFetch(true);
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <DataList
        sectionTitle="Channels"
        createNewLabel={"Add Channel"}
        data={dataList}
        primaryKey="uuid"
        orderColumn="createdAt"
        emptyIcon="playlist_play"
        avatarKey="_"
        loading={loading}
        onCreateNewItem={() => navigate(
          `${AppRoutes.channels}/${AppRoutes.channel}`,
          true
        )}
        headers={getTableHead()}
        rowData={(data) => {
          const _entries = [
            { value: formatDate(data.createdAt, "hh:mm A  MMM DD, YYYY"), width: 200 },
            { value: data.name, width: 200 },
            { value: data.code || "-", width: 200 },
            { value: data.expires, width: 200 },
            { ...getStatus(data.status), width: 80 },
          ];
          return {
            exclude: [
              `${data.status}`.toLowerCase() === "expired" ? "re-auth":"auth",
              `${data.status}`.toLowerCase().startsWith("needs") ? "auth" : "re-auth",
            ],
            entries: _entries,
          };
        }}
        selectionOptions={[]}
        filterOptions={filterOptions}
        optionItems={actionOptions}
      />
    </div>
  );
};

export default ChannelList;
