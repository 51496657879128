import PropTypes from "prop-types";
import {Icon, Typography } from "@mui/material";
import { coreStyles } from "../../../theme/style";
SearchNotFound.propTypes = {
  searchQuery: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.string
};

export default function SearchNotFound({ searchQuery = "", label, icon, ...other }) {
  const wasASearch = searchQuery && searchQuery.length > 0,
  appStyle = coreStyles();
  return (
    <div {...other}>
      <Typography
        gutterBottom
        align="center"
        variant="h2"
        mt={6}
        color="primary.darker"
      >
        {wasASearch ? "Search result" : `No ${label} found`}
      </Typography>
      {!wasASearch && <Icon children={icon} className={appStyle.icon_empty}/>}
      {wasASearch && (
        <Typography component="h6" variant="body2" align="center" mt={4} mb={7}>
          We couldn't find what you are looking for &nbsp;
          <strong>&quot;{searchQuery}&quot;</strong>. Try checking for typos or
          using complete content.
        </Typography>
      )}
    </div>
  );
}
