import {
  navigate,
  guardRoute,
  sendGetRequest,
  formatDate,
  getUserStatus,
  sendDeleteRequest
} from "../utils/utils";
import DataList from "./component/@list/list";
import { useState, useEffect } from "react";
import AppRoutes from "../routing/routes";

const getTableHead = () => {
  return [
    { id: "createAt", label: "Added on", alignRight: false },
    { id: "name", label: "Bucket Name", alignRight: false },
    { id: "region", label: "Region", alignRight: false },
    { id: "cloudFrontUrl", label: "Public URL", alignRight: false },
    { id: "_", alignRight: false },
  ];
};

const BucketsList = () => {
  guardRoute();
  const [dataList, setDataList] = useState([]);
  const [loading, onLoading] = useState(false);


  const actionOptions = [
    {
      icon: "delete",
      value: "delete",
      label: "Delete",
      onClick: (value, selected) => {
        onLoading(true);
        Promise.all(selected.map(uuid => sendDeleteRequest('buckets', uuid))).then(response => {
          executeFetch();
        })
      },
    },
    {
      icon: "edit_note",
      value: "edit",
      label: "Edit",
      onClick: (_value, selected) =>
        navigate(
          `${AppRoutes.buckets}/${AppRoutes.bucket}?uuid=${selected.join("")}`,
          true
        ),
    },
  ];
  const filterOptions = [];

  const executeFetch = (refresh = false) => {
    sendGetRequest('buckets?metadata=true', (loading) => onLoading(loading),
      (data) => setDataList(data));
  };

  useEffect(() => {
    executeFetch();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <DataList
        sectionTitle="Content sources"
        createNewLabel={"Add Sources"}
        data={dataList}
        primaryKey="uuid"
        orderColumn="name"
        emptyIcon="playlist_play"
        avatarKey="_"
        loading={loading}
        onBack={() => navigate(`${AppRoutes.contents}`, true)}
        onCreateNewItem={() => navigate(
          `${AppRoutes.buckets}/${AppRoutes.bucket}`,
          true
        )}
        headers={getTableHead()}
        rowData={(data) => {
          const _entries = [
            { value: formatDate(data.createdAt, "hh:mm A  MMM DD, YYYY"), width: 200 },
            { value: data.name, width: 200 },
            { value: data.region },
            { value: data.cloudFrontUrl, width: 200 },
           { ...getUserStatus(data.inuse) },
            
          ];
          return {
            exclude: [],
            entries: _entries,
          };
        }}
        selectionOptions={[]}
        filterOptions={filterOptions}
        optionItems={actionOptions}
      />
    </div>
  );
};

export default BucketsList;
