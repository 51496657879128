import {
    Container,
    Typography,
    Icon,
    IconButton,
    Button,
    Stack,
    DialogContent,
    Dialog,
    DialogActions,
    DialogTitle,
    Box, Grid
} from "@mui/material";
import "react-dropzone-uploader/dist/styles.css";
import { coreStyles } from "../theme/style";
import EditPage from "./component/edit-page";
import {
    guardRoute,
    sendGetRequest,
    sendPatchRequest,
    navigate,
    sendDeleteRequest,
    sendPostRequest,
} from "../utils/utils";
import EasyTextEdit from "./component/text-edit";
import { useState, useEffect } from "react";
import DataListWithSearch from "./component/@list/list-search";
import AppRoutes from "../routing/routes";

const _ref = {
    uuid: null,
    title: null,
    order: 1
}


const HomeContentEdit = () => {
    guardRoute(false, true);
    let [contents, setContents] = useState([]);
    let [dialogContent, setDialogContents] = useState([]);
    let [allContents, setAllContents] = useState([]);
    let [sections, setSections] = useState([]);
    let [open, setOpen] = useState({ open: false, index: 0 });
    let [addEdit, setAddEdit] = useState({ open: false, content: _ref });
    const [loading, onLoading] = useState(false);
    const appStyle = coreStyles()

    const userOptions = [
        {
            icon: "check",
            value: "select",
            label: "Select",
            onClick: (value, selected) => {
                setOpen({ ...open, open: false });
                const _contents = contents.concat(allContents.filter(cont => selected.includes(cont.uuid)).map(cont => {
                    return { ...cont, index: open.index };
                }));
                setContents(_contents);
            },
        },
    ];

    const fetchContents = () => {
        onLoading(true)
        sendGetRequest('contents', (loading) => {
        }, (data) => {
            setAllContents(data);
            sendGetRequest(
                "home",
                (loading) => onLoading(loading),
                (data) => {
                    setSections(
                        data.map((sec, index) => {
                            return { title: sec.title, index, uuid: sec.uuid, order: sec.order };
                        })
                    );
                    let processedContents = [];
                    data.forEach((cont, index) => {
                        processedContents = processedContents.concat(
                            cont.contents.map((content) => {
                                return { ...content, index };
                            })
                        );
                    });
                    setContents(processedContents);
                }
            );

        }).then(res => {
        });
    }

    useEffect(() => {
        fetchContents();
    }, []);

    const deleteContent = (uuid) => {
        setContents(contents.filter(content => content.uuid !== uuid));
    };

  const updateOrder = (data, order) => {
    const dataIndex = contents.findIndex((content) => content.uuid === data.uuid);
    contents[dataIndex] = { ...data, order: order.length > 0 ? +order : null };
    setContents(contents);
    setSections(sections.splice(0,sections.length));
  }

    const addContent = (index) => {
        const _content = contents.map(cont => cont.uuid);
        setDialogContents(allContents.filter(cont => !_content.includes(cont.uuid)))
        setOpen({ open: true, index });
    }

    const handleClose = (edit) => {
        if (edit) {
            setAddEdit({ ...addEdit, open: false })
        } else {
            setOpen({ ...open, open: false });
        }
    }

    const onValueChange = (value, tag) => {
        addEdit.content = { ...addEdit.content, [tag]: value }
        setAddEdit({ ...addEdit });
    };

    return (
      <div>
        <Dialog
          open={addEdit.open}
          onClose={() => handleClose(true)}
          fullWidth
          maxWidth={"md"}
        >
          <DialogContent>
            <div sx={{ width: `700px !important;` }}>
              <EditPage
                title={`${addEdit.content.uuid ? "Edit " : "Add "} ${
                  addEdit.content.uuid ? addEdit.content.title : `home section`
                }`}
                status={{ loading: false }}
                btnLabel={`${addEdit.content.uuid ? "Update " : "Add "} Now`}
                onSave={() => {
                  if (!addEdit.content.uuid) {
                    delete addEdit.content.uuid;
                    sendPostRequest(
                      `home`,
                      addEdit.content,
                      (loading) => onLoading(loading),
                      (res) => {
                        fetchContents();
                        handleClose(true);
                      }
                    );
                  } else {
                    sendPatchRequest(
                      `home/${addEdit.content.uuid}`,
                      addEdit.content,
                      (loading) => onLoading(loading),
                      (res) => {
                        fetchContents();
                        handleClose(true);
                      }
                    );
                  }
                }}
                validated={
                  addEdit.content.title && addEdit.content.order && !loading
                }
                onFinish={() => {}}
              >
                <Grid item xs={6} xl={12}>
                  <EasyTextEdit
                    label="Section title"
                    placeholder="Home section title to be displayed"
                    type="text"
                    id="title"
                    errorText=""
                    textField={true}
                    disabled={loading}
                    value={addEdit.content.title}
                    onChange={onValueChange}
                  />
                </Grid>

                <Grid item xs={6} xl={12}>
                  <EasyTextEdit
                    label="Section Order"
                    placeholder="Order of the section on home page"
                    type="text"
                    id="order"
                    errorText=""
                    textField={true}
                    disabled={loading}
                    value={addEdit.content.order}
                    onChange={onValueChange}
                  />
                </Grid>
              </EditPage>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleClose(true)}>Cancel</Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={open.open}
          onClose={() => handleClose(false)}
          fullWidth
          maxWidth={"md"}
        >
          {sections[open.index] && (
            <DialogTitle>{`Select contents for the ${
              sections[open.index].title
            } section`}</DialogTitle>
          )}
          <DialogContent>
            <div>
              <DataListWithSearch
                data={dialogContent.filter(data => data.section && data.section.title)}
                primaryKey="uuid"
                filterColumn={"title"}
                emptyIcon="toc"
                avatarKey="uuid"
                sectionTitle=""
                loading={loading}
                headers={[]}
                rowData={(data) => {
                  const _entries = [
                    { value: data.title, width: 300 },
                    { value: data.section.title, width: 250 },
                    { value: data.section.category.name, width: 160 }
                  ];
                  return {
                    exclude: [],
                    entries: _entries,
                  };
                }}
                selectionOptions={userOptions}
                filterOptions={[]}
                optionItems={userOptions}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleClose(false)}>Cancel</Button>
          </DialogActions>
        </Dialog>
        <Stack
          sx={{ marginRight: 6 }}
          direction="row"
          justifyContent="space-between"
          alignItems="end"
        >
          <IconButton
            aria-label="back"
            sx={{ margin: `0px 16px 0px 18px !important;` }}
            onClick={() => navigate(`${AppRoutes.contents}/${AppRoutes.types}`, true)}
          >
            <Icon children={"arrow_back"} />
          </IconButton>
          <Stack direction="row">
            <Button
              variant="filled"
              align="end"
              onClick={async () => setAddEdit({ content: _ref, open: true })}
              className={appStyle.actionButonCustom}
              startIcon={
                <Icon children={"add"} className={appStyle.actionButonIcon} />
              }
            >
              {"Add section"}
            </Button>
            <Button
              variant="contained"
              align="end"
              onClick={async () => {
                const experience = sections.map((sec, index) => {
                  return {
                    ...sec,
                    contents: contents
                      .filter((con) => con.index === index)
                      .map((cont) => {
                        return {uuid: cont.uuid, order: cont.order}
                      }),
                  };
                });
                onLoading(true);
                await Promise.all(
                  experience.map((exp) => {
                    return sendPatchRequest(
                      `home/${exp.uuid}`,
                      exp,
                      (loading) => {},
                      (data) => {}
                    );
                  })
                ).then((res) => {
                  onLoading(false);
                  navigate(`${AppRoutes.contents}/${AppRoutes.types}`, true);
                });
              }}
              disabled={
                (contents.filter((cont) => cont.index === 0).length === 0 ||
                  contents.filter((cont) => cont.index === 1).length === 0) &&
                !loading
              }
              className={appStyle.actionButonCustom}
              startIcon={
                <Icon children={"check"} className={appStyle.actionButonIcon} />
              }
            >
              {"Save experience"}
            </Button>
          </Stack>
        </Stack>

        {sections.map((section, index) => {
          return (
            <Container sx={{ minWidth: 900 }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ marginRight: 2 }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ margin: `24px 10px !important;` }}
                >
                  {`${section.title}`}
                </Typography>
                <Box
                  justifyContent="space-between"
                  direction="row"
                  sx={{ marginTop: `16px !important;` }}
                >
                  <IconButton
                    aria-label="edit"
                    sx={{ marginRight: `16px !important;` }}
                    onClick={() =>
                      setAddEdit({ ...addEdit, open: true, content: section })
                    }
                  >
                    <Icon children={"edit"} />
                  </IconButton>

                  <IconButton
                    aria-label="add"
                    onClick={() => addContent(section.index)}
                  >
                    <Icon children={"list"} />
                  </IconButton>

                  <IconButton
                    aria-label="delete"
                    sx={{ marginRight: `16px !important;` }}
                    onClick={() =>
                      sendDeleteRequest(
                        `home`,
                        section.uuid,
                        (loading) => onLoading(loading),
                        (data) => fetchContents()
                      )
                    }
                  >
                    <Icon children={"delete"} />
                  </IconButton>
                </Box>
              </Stack>
              <DataListWithSearch
                data={contents.filter(
                  (content) => content.index === section.index && content.section
                )}
                primaryKey="uuid"
                filterColumn={[]}
                emptyIcon=""
                sectionTitle={`${section.title} contents`}
                avatarKey="uuid"
                loading={index === 0 ? loading : false}
                headers={[]}
                rowData={(data) => {
                  const _entries = [
                    { value: data.title, width: 350 },
                    { value: data.section.title, width: 250 },
                    { value: data.section.category.name, width: 160 },

                    {
                      value: (
                        <EasyTextEdit
                          label="Home Order"
                          placeholder="Content order"
                          type="text"
                          id={data.uuid}
                          errorText=""
                          textField={true}
                          disabled={false}
                          value={data.order}
                          onChange={(order, tag) => updateOrder(data, order)}
                        />
                      ),
                    },
                    {
                      value: (
                        <IconButton
                          aria-label="delete"
                          onClick={() => deleteContent(data.uuid)}
                        >
                          <Icon children={"delete"} />
                        </IconButton>
                      ),
                    },
                  ];
                  return {
                    exclude: [],
                    entries: _entries,
                  };
                }}
                selectionOptions={[]}
                filterOptions={[]}
                optionItems={[]}
              />
            </Container>
          );
        })}
      </div>
    );
};

export default HomeContentEdit;