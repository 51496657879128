import { configureStore } from "@reduxjs/toolkit";
import navigatorReducer from "./navigator";
import snackbarReducer from "./snackbar";
import socketReducer from "./socket";
import videoReducer from "./videos";
const AppStore = configureStore({
  reducer: {
    navigator: navigatorReducer,
    snackbar: snackbarReducer,
    socket: socketReducer,
    videos: videoReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
export default AppStore;
