import { Grid } from "@mui/material";
import "react-dropzone-uploader/dist/styles.css";
import {
  getParams,
  navigate,
  guardRoute,
  sendGetRequest,
  sendPostRequest,
} from "../utils/utils";
import EditPage from "./component/edit-page";
import { useState, useEffect } from "react";
import AppRoutes from "../routing/routes";
import EasyTextEdit from "./component/text-edit";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel
} from "@mui/material";

const _ref = {
  price: null,
  package: null,
  user: null,
  transactionId: null
};


const PaymentEdit = () => {
  guardRoute(false, true);
  const { uuid } = getParams();
  var [payment, setPayment] = useState(_ref);
  var [packages, setPackages] = useState([]);

  const [status, setStatus] = useState({
    error: false,
    loading: false,
    message: null,
  });
  useEffect(() => {
     sendGetRequest(`packages`,
       (loading) => setStatus({ ...{ loading: loading } }), (data) => setPackages(data))
    return () => setPayment(_ref);
    // eslint-disable-next-line
  }, [uuid]);

  const createUpdate = () => {
    sendPostRequest('paywall', { ...payment, purchasedOn: new Date().getTime() },
      (loading) => setStatus({ ...{ loading: loading } }), (data) => {
        setStatus({
          ...{
            error: data.error,
            message: data.message,
          },
        });
      });
  };

  const onValueChange = (value, tag) => setPayment({ ...payment, [tag]: value });

  return (
    <EditPage
      title={uuid ? `Edit Payment` : "Clear payment"}
      status={status}
      btnLabel={uuid ? "Update Now" : "Clear Now"}
      onSave={() => createUpdate()}
      validated={
        payment.price &&
        payment.transactionId &&
        payment.package &&
        payment.user &&
        !status.loading
      }
      onBack={() =>
        navigate(`${AppRoutes.payments}`, true)
      }
      onFinish={() => {
        setStatus({ ...{ loading: false, message: null, error: false } });
        if (!status.error) {
          setPayment({ ..._ref });
          navigate(`${AppRoutes.payments}`, true);
        }
      }}
    >
      <Grid item xs={2} xl={12}>
        <EasyTextEdit
          label="Payment amount"
          placeholder="Store specific amount as per location"
          type="text"
          id="price"
          errorText=""
          textField={true}
          disabled={status.loading}
          value={payment.price}
          onChange={onValueChange}
        />
      </Grid>

      <Grid item xs={4} xl={12}>
        <EasyTextEdit
          placeholder="Transaction ID"
          label="Store transaction receipt ID"
          type="text"
          id="transactionId"
          textField={true}
          errorText=""
          disabled={status.loading}
          value={payment.transactionId}
          onChange={onValueChange}
        />
      </Grid>

      <Grid item xs={3} xl={12}>
        <EasyTextEdit
          placeholder="Purchased by"
          label="Email of the user who purchased"
          type="text"
          id="user"
          textField={true}
          errorText=""
          disabled={status.loading}
          value={payment.user}
          onChange={onValueChange}
        />
      </Grid>

      <Grid item xs={3} xl={12}>
        <FormControl sx={{ minWidth: 50 }} fullWidth>
          <InputLabel id="packages">Purchased package</InputLabel>
          <Select
            value={payment.playstore}
            label="Purchased package"
            labelId="packages"
            id="package"
            onChange={(e) => onValueChange(e.target.value, "package")}
          >
            {packages.map((pack) => {
              return (
                <MenuItem value={pack.uuid}>
                  &nbsp;&nbsp;&nbsp;{`${pack.storeRef} - ${pack.playstore ? "Android":"iOS"}`}
                  <br />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>

    </EditPage>
  );
};

export default PaymentEdit;