import { Grid } from "@mui/material";
import "react-dropzone-uploader/dist/styles.css";
import {
  getParams,
  navigate,
  guardRoute,
  sendGetRequest,
  sendPostRequest,
  sendPatchRequest,
} from "../utils/utils";
import EditPage from "./component/edit-page";
import { useState, useEffect } from "react";
import AppRoutes from "../routing/routes";
import EasyTextEdit from "./component/text-edit";
import {
  FormControl,
  MenuItem,
  Select,
  InputLabel
} from "@mui/material";

const _ref = {
  uuid: "",
  category: "",
  thumbnail: "",
  order: "",
  title: "",
  active: true
};


const ContentSectionEdit = () => {
  guardRoute(false, true);
  const { uuid } = getParams();
  var [section, setSection] = useState(_ref);
  var [categories, setCategories] = useState([]);
  var [thumbError, setThumbError] = useState(false);

  const [status, setStatus] = useState({
    error: false,
    loading: false,
    message: null,
  });
  useEffect(() => {
    sendGetRequest('categories', (loading) => setStatus({ ...{ loading: true } }), (types) => {
      types.sort((prev, next) => prev.order - next.order)
      setCategories(types.filter(category => category.name !== "Home"));
      sendGetRequest(`sections?uuid=${uuid}`,
      (loading) => setStatus({ ...{ loading: loading } }), (data) => setSection({...data, category: data.category ? data.category.uuid: types[0].uuid}))
    });
    return () => setSection(_ref);
    // eslint-disable-next-line
  }, [uuid]);

  const createUpdate = () => {
    if (!uuid) {
      delete section.uuid;
      sendPostRequest('sections', {...section, active: true},
        (loading) => setStatus({ ...{ loading: loading } }), (data) => {
          setStatus({
            ...{
              error: data.error,
              message: data.message,
            },
          });
        });
    } else {
      section.uuid = uuid;
      sendPatchRequest(`sections/${uuid}`, section,
        (loading) => setStatus({ ...{ loading: loading } }), (data) => {
          setStatus({
            ...{
              error: data.error,
              message: data.message,
            },
          });
        });
    }
  };

  const onValueChange = (value, tag) => {
    setSection({ ...section, [tag]: value });
    if(tag === "thumbnail"){
      setThumbError(
        !(`${value}`.startsWith("s3://") && `${value}`.includes("."))
      );
    }
  };

  return (
    <EditPage
      title={uuid ? `Edit content section` : "Add content section"}
      status={status}
      btnLabel={uuid ? "Update Now" : "Add Now"}
      onSave={() => createUpdate()}
      onBack={() =>
        navigate(`${AppRoutes.contents}/${AppRoutes.sections}`, true)
      }
      validated={
        !thumbError &&
        section.title &&
        section.order &&
        section.category &&
        section.thumbnail &&
        !status.loading
      }
      onFinish={() => {
        setStatus({ ...{ loading: false, message: null, error: false } });
        if (!status.error) {
          setSection({ ..._ref });
          navigate(`${AppRoutes.contents}/${AppRoutes.sections}`, true);
        }
      }}
    >
      <Grid item xs={6} xl={12}>
        <EasyTextEdit
          label="Section title"
          placeholder="Name of the content section"
          type="text"
          id="title"
          errorText=""
          textField={true}
          disabled={status.loading}
          value={section.title}
          onChange={onValueChange}
        />
      </Grid>

      <Grid item xs={3} xl={12}>
        <FormControl sx={{ minWidth: 200 }} fullWidth>
          <InputLabel id="type-label">Content type</InputLabel>
          <Select
            value={section.category}
            label="Content Type"
            labelId="type-label"
            id="category"
            autoWidth
            onChange={(e) => {
              if (section.category !== e.target.value) {
                onValueChange(e.target.value, "category");
              }
            }}
          >
            {(categories || []).map((category) => {
              return (
                <MenuItem value={category.uuid}>
                  &nbsp;&nbsp;&nbsp;{category.name}
                  <br />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={3} xl={12}>
        <EasyTextEdit
          placeholder="Ordering preference"
          label="Order"
          type="text"
          id="order"
          textField={true}
          errorText=""
          disabled={status.loading}
          value={section.order}
          onChange={onValueChange}
        />
      </Grid>

      <Grid item xs={12} xl={12}>
        <EasyTextEdit
          placeholder="S3 file URI i.e s3://bucket-id/folder/directory/image.jpg"
          label="Section thumbnail image"
          type="text"
          textField={true}
          error={thumbError}
          id="thumbnail"
          errorText={thumbError ? "Make sure you fill in AWS s3 file URI" : ""}
          disabled={status.loading}
          value={section.thumbnail}
          onChange={onValueChange}
        />
      </Grid>
    </EditPage>
  );
};

export default ContentSectionEdit;