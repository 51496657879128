import PropTypes from "prop-types";
import { forwardRef } from "react";
import { coreStyles } from "../../theme/style";
import {
  TextField,
  TableCell
} from "@mui/material";

const CustomTableCell = forwardRef(
  ({ row, name = "", placeholder = "", onChange }, ref) => {
    const appStyle = coreStyles();
    const { isEditMode } = row;
    return (
      <TableCell align="left" className={appStyle.tableCell}>
        {isEditMode ? (
          <TextField
            id={`${row["uuid"]}`}
            label={`${placeholder}`}
            placeholder={`${placeholder}`}
            value={row[name]}
            name={name}
            onChange={(e) => onChange(e, row)}
            className={appStyle.inputs}
            multiline
            rows={3}
          />
        ) : (
          row[name] || "No video description (Optional)"
        )}
      </TableCell>
    );
  }
);

CustomTableCell.propTypes = {
  row: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CustomTableCell;
