import {
  Box,
  Typography,
  Stack,
  Avatar,
} from "@mui/material";
import { getActiveUser, getAvatarLabel, getUserRole } from "../../utils/utils";
import { coreStyles } from "../../theme/style";

export default function AccountProfile() {
  const loggedUser = getActiveUser();
  const label = getAvatarLabel(loggedUser, "firstName");
  const appStyle = coreStyles();

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Avatar src={loggedUser.profileUrl} alt="photoURL">
          {label}
        </Avatar>

        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography
            variant="subtitle1"
            className={appStyle.inputsNoPadding}
            align="left"
            color="primary.darker"
            noWrap
          >
            {`${loggedUser.firstName} ${loggedUser.lastName}`}
          </Typography>
          <Typography variant="body2" color = "text.secondary" noWrap>
            {getUserRole(loggedUser.role) } Personnel
          </Typography>
        </Box>
      </Stack>
    </>
  );
}
