import {
  FormControl,
  MenuItem,
  Icon,
  IconButton,
  Select,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
} from "@mui/material";
import "react-dropzone-uploader/dist/styles.css";
import { coreStyles } from "../theme/style";
import {
  guardRoute,
  subscribe,
  getVideos,
  SetVideos,
  navigate,
} from "../utils/utils";
import EditPage from "./component/edit-page";
import { useState, useEffect } from "react";
import AppRoutes from "../routing/routes";
import CustomTableCell from "./component/table-cell";

const TaskAdd = () => {
  const appStyle = coreStyles();
  guardRoute(false, true);
  const [videos, setVideos] = useState([]);
  const [channels, setChannels] = useState([]);
  const [previous, setPrevious] = useState({});

  const onToggleEditMode = (id) => {
    setVideos((state) => {
      return videos.map((row) => {
        if (row.video_key === id) {
          return { ...row, isEditMode: !row.isEditMode };
        }
        return row;
      });
    });
  };

  const onChange = (e, row) => {
    if (!previous[row.video_key]) {
      setPrevious((state) => ({ ...state, [row.video_key]: row }));
    }
    const value = e.target.value;
    const name = e.target.name;
    const { video_key } = row;
    const newRows = videos.map((row) => {
      var taskRow = row;
      if (row.video_key === video_key) {
        if(name === "video_channel"){
          const desc = channels.filter(chan => chan._id === value)[0].channel_video_desc;
          taskRow = {...taskRow, video_description: desc};
        }
        return { ...row,...taskRow, [name]: value };
      }
      return row;
    });
    setVideos(newRows);
  };

  const [status, setStatus] = useState({
    error: false,
    loading: false,
    message: null,
  });

  const convertImageToBlob = (event, blobFile) => {
    let reader = new FileReader();
    reader.onload = (e) => blobFile(e.target.result);
    reader.readAsDataURL(event.target.files[0]);
  };

  const processSelectedThumb = async (event, video) =>
    convertImageToBlob(event, (file) => onChange({target: {value: file, name:"video_thumb"}}, video));

  const onRevert = (id) => {
    const newRows = videos.map((row) => {
      if (row.video_key === id) {
        return previous[id] ? previous[id] : row;
      }
      return row;
    });
    setVideos(newRows);
    setPrevious((state) => {
      delete state[id];
      return state;
    });
    onToggleEditMode(id);
  };

  const createUploadingTask = () => {
    subscribe(
      "task/createTask",
      videos.map(_video => {
        return [_video];
      }),
      (_, result) => {
        if (result) setStatus(result);
      },
      (loading) => setStatus({...status, loading: loading})
    );
  };

  useEffect(() => {
    Promise.all([
      subscribe(
        "task/findChannels?active=true",
        null,
        (_, result) => {
          if (result) setChannels(result);
        },
        (loading) => {}
      ),
    ]).then((response) => {});
    const _videos = getVideos() || [];
    if(_videos.length === 0){
      navigate(`${AppRoutes.videos}`, true);
    }else {
      setVideos(_videos);
    }
    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <EditPage
      title="Prepare videos "
      btnLabel="Create Task"
      status={status}
      onSave={() => createUploadingTask()}
      validated={
        videos.filter((video) => video.isEditMode).length === 0 &&
        videos.filter((video) => video.video_channel === null).length === 0 &&
        videos.filter((video) => video.task_fade === null).length === 0
      }
      onFinish={() => {
        setStatus({ ...{ loading: false, message: null, error: false } });
        if (!status.error) {
          navigate(`${AppRoutes.tasks}`, true);
        }
      }}
    >
      <TableBody>
        {videos.map((video) => (
          <TableRow key={video.video_key}>
            <TableCell>
              <input
                style={{ display: "none" }}
                id={`${video.video_key}`}
                accept="image/jpg,image/jpeg,image/png"
                onChange={(event) => processSelectedThumb(event, video)}
                type="file"
              />

              <Tooltip
                title={
                  video.video_thumb ? (
                    <>
                      <img alt="" src={video.video_thumb} className={appStyle.preview_image} />
                      <IconButton
                        aria-label="delete"
                        className={appStyle.preview_margin}
                        onClick={() => onChange({target: {value: null, name: "video_thumb"}}, video)}
                      >
                        <Icon
                          children={"delete"}
                          className={appStyle.input_icons}
                        />
                      </IconButton>
                    </>
                  ) : (
                    "No preview, click to upload"
                  )
                }
              >
                <IconButton
                  onClick={() => {
                    document.getElementById(`${video.video_key}`).click();
                  }}
                >
                  <Icon
                    children={"add_photo_alternate"}
                    color={video.video_thumb ? "success" : "inherit"}
                  />
                </IconButton>
              </Tooltip>
            </TableCell>
            <TableCell className={appStyle.selectTableCell}>
              {video.isEditMode ? (
                <>
                  <IconButton
                    aria-label="done"
                    onClick={() => onToggleEditMode(video.video_key)}
                  >
                    <Icon children={"done"} className={appStyle.input_icons} />
                  </IconButton>
                  <IconButton
                    aria-label="revert"
                    onClick={() => onRevert(video.video_key)}
                  >
                    <Icon children={"close"} className={appStyle.input_icons} />
                  </IconButton>
                </>
              ) : (
                <IconButton
                  aria-label="edit"
                  onClick={() => onToggleEditMode(video.video_key)}
                >
                  <Icon
                    children={"edit_note"}
                    className={appStyle.input_icons}
                  />
                </IconButton>
              )}
            </TableCell>
            <CustomTableCell
              row={video}
              name={"video_name"}
              placeholder="Video name"
              onChange={(e, row) => onChange(e, row)}
            />
            <CustomTableCell
              row={video}
              name={"video_description"}
              placeholder="Video description"
              onChange={(e, row) => onChange(e, row)}
            />

            <TableCell>
              <FormControl
                fullWidth
                sx={{ ml: 1 }}
                variant={video.isEditMode ? null : "standard"}
              >
                <Select
                  className={appStyle.tableCellSelectField}
                  inputlabelprops={{ shrink: true }}
                  value={video.video_channel}
                  name="video_channel"
                  onChange={(e) => onChange(e, video)}
                >
                  {channels.map((option) => {
                    return (
                      <MenuItem value={option._id} className={appStyle.inputs}>
                        &nbsp;&nbsp;&nbsp;{option.channel_name}
                        <br />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </TableCell>

            <TableCell>
              <FormControl
                fullWidth
                sx={{ ml: 1 }}
                variant={video.isEditMode ? null : "standard"}
              >
                <Select
                  className={appStyle.tableCellSelectField}
                  inputlabelprops={{ shrink: true }}
                  value={video.task_fade}
                  name="task_fade"
                  onChange={(e) => onChange(e, video)}
                >
                  {[true, false].map((option) => {
                    return (
                      <MenuItem value={option} className={appStyle.inputs}>
                        &nbsp;&nbsp;&nbsp;{option ? 'White Fade':'Black Fade'}
                        <br />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </TableCell>

            <TableCell>
              <IconButton
                onClick={() => {
                  const _rows = videos.filter(
                    (_video) => _video.video_key !== video.video_key
                  );
                  if (_rows.length === 0) navigate(`${AppRoutes.videos}`, true);
                  setVideos(_rows);
                  SetVideos(_rows);
                }}
              >
                <Icon children={"delete"} />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </EditPage>
  );
};

export default TaskAdd;
