import {
  getActiveUser,
  navigate,
  guardRoute,
  sendGetRequest,
  getUserStatus,
  UserRoles,
  formatDate,
  sendPatchRequest,
  sendDeleteRequest
} from "../utils/utils";
import DataListWithSearch from "./component/@list/list-search";
import { useState, useEffect } from "react";
import AppRoutes from "../routing/routes";

const getTableHead = () => {
  return [
    { id: "created", label: "Created On", alignRight: false },
    { id: "name", label: "Title", alignRight: false },
    { id: "category", label: "Content type", alignRight: false },
    { id: "order", label: "Order", alignRight: false },
    { id: "active", label: "State", alignRight: false },
  ];
};

const ContentSections = () => {
  guardRoute();
  const loggedUser = getActiveUser();
  const [dataList, setDataList] = useState([]);
  const [loading, onLoading] = useState(false);
  const [filterKey, setFilterkey] = useState("title");

  const userOptions = [
    {
      icon: "delete",
      value: "delete",
      label: "Delete",
      onClick: (value, selected) =>{
        onLoading(true);
        Promise.all(selected.map(uuid => sendDeleteRequest('sections', uuid))).then(response => {
          executeFetch();
        })
      }
    },
    {
      icon: "edit",
      value: "edit",
      label: "Edit",
      onClick: (_value, selected) => startEdit(selected.join(""))
    },
    {
      icon: "toggle_on",
      value: "activate",
      label: "Activate",
      onClick: (_value, selected) => executeUpdate(true, selected)
    },
    {
      icon: "toggle_off",
      value: "deactivate",
      label: "Deactivate",
      onClick: (_value, selected) => executeUpdate(false, selected),
    },
  ];

  const startEdit = (uuid) => navigate(`${AppRoutes.contents}/${AppRoutes.section}${uuid ? `?uuid=${uuid}`:''}`,true);

  const filterOptions = [
    { value: "title", label: "Title", onClick: setFilterkey },
    { value: "category", label: "Content type", onClick: setFilterkey }
  ];

  const executeFetch = () => {
    sendGetRequest('sections?metadata=true', (loading) => onLoading(loading), (data) => setDataList(data.map(section => {
      return {
        uuid: section.uuid,
        active: section.active ? section.active: false,
        title: section.title,
        order: section.order,
        createdAt: section.createdAt || new Date().getTime(),
        category: section.category.name,
      };
    })));
  };


  useEffect(() => executeFetch(), []);

  const executeUpdate = (active, ids) => {
    onLoading(true);
    Promise.all(
      ids.map((id) => sendPatchRequest(`sections/${id}`, { active }))
    ).then((res) => {
      executeFetch();
      onLoading(false);
    });
  };

  return (
    <div>
      <DataListWithSearch
        sectionTitle="Content sections"
        data={dataList}
        createNewLabel={"Add content section"}
        primaryKey="uuid"
        filterColumn={filterKey}
        emptyIcon="segment"
        avatarKey="uuid"
        loading={loading}
        onCreateNewItem={() => startEdit()}
        headers={getTableHead()}
        onBack={() =>
          navigate(`${AppRoutes.contents}`, true)
        }
        rowData={(data) => {
          const _entries = [
            { value: formatDate(data.createdAt) },
            { value: data.title, width: 160 },
            { value: data.category, width: 160 },
            { value: data.order },
            { ...getUserStatus(data.active) },
          ];
          return {
            exclude: [
              loggedUser.role === UserRoles.editor ? "delete" : "",
              data.active ? "activate" : "deactivate",
            ],
            entries: _entries,
          };
        }}
        selectionOptions={userOptions.filter((option) => {
          return (
            option.value !== "edit" ||
            (loggedUser.role === UserRoles.editor
              ? option.value !== "delete"
              : false)
          );
        })}
        filterOptions={filterOptions}
        optionItems={userOptions}
      />
    </div>
  );
};

export default ContentSections;