import { Navigate, useRoutes } from "react-router-dom";
import AuthLogin from "../views/auth-login";
import Dashboard from "../views/dashboard";
import AppRoutes from "./routes";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateNavigator } from "../utils/@redux/navigator";
import { setSocket } from "../utils/@redux/socket";
import { io } from "socket.io-client";
import { getActiveUser, navigate, baseUrl } from "../utils/utils";
import { useEffect } from 'react';
import AppStore from "../utils/@redux/store";
import TaskAdd from "../views/task-add";
import TaskList from "../views/tasks";
import ChannelList from "../views/channels";
import ChannelEdit from "../views/channel-edit";
import CombineVideosTaskAdd from "../views/combine-video-task-add";
import BucketsList from "../views/buckets";
import BucketEdit from "../views/bucket-edit";
import UserList from "../views/users";
import ContentList from "../views/contents";
import ContentTypeList from "../views/contents-types";
import ContentSections from "../views/contents-sections";
import ContentTypeEdit from "../views/contents-type-edit";
import ContentSectionEdit from "../views/contents-section-edit";
import ContentEdit from "../views/contents-edit";
import UserEdit from "../views/users-edit";
import PasswordChange from "../views/password-change";
import HomeContentEdit from "../views/home-content-edit";
import CiCdInformation from "../views/ci-cd";
import LanguageList from "../views/languages";
import LanguageEdit from "../views/languags-edit";
import PackageList from "../views/packages";
import PackageEdit from "../views/package-edit";
import PaymentList from "../views/payments";
import PaymentEdit from "../views/payment-edit";
import LinkPlayer from "../views/stream-player";
import FreeTrialEdit from "../views/free-trail";
import AppSettings from "../views/app-setting";
import BackgroundMusic from "../views/background-music";
import BackgroundMusics from "../views/background-musics";
import { GameList } from "../views/games";
import { GameEdit } from "../views/game-edit";

var socket = null;

export default function AppRouter() {
  useDispatch()(updateNavigator(useNavigate()));
  socket = AppStore.getState().socket.instance;
  if(socket === null){
    socket = io(baseUrl().split("/v")[0], {
      transports: ["websocket"],
      secure: true,
      autoConnect: false,
    });
  }
  useDispatch()(setSocket(socket));
  
  useEffect(() => {
    socket.connect();
    if (window.location.href.includes(AppRoutes.auth) && getActiveUser()) {
      navigate(AppRoutes.videos, true);
    }
    return () => {
      socket.disconnect(true);
    };
    // eslint-disable-next-line
  }, []);

  return useRoutes([
    {
      path: `${AppRoutes.dashboard}`,
      element: <Dashboard />,
      children: [
        { path: `${AppRoutes.newTask}`, element: <TaskAdd /> },
         { path: `${AppRoutes.newCombineTask}`, element: <CombineVideosTaskAdd /> },
         { path: `${AppRoutes.cicd}`, element: <CiCdInformation /> },
        {
          path: `${AppRoutes.channels}`,
          children: [
            { path: "", element: <ChannelList /> },
            { path: `${AppRoutes.channel}`, element: <ChannelEdit /> },
          ],
        },
        {
          path: `${AppRoutes.games}`,
          children: [
            { path: "", element: <GameList /> },
            { path: `${AppRoutes.game}`, element: <GameEdit /> },
          ],
        },

        {
          path: `${AppRoutes.tasks}`,
          children: [
            { path: "", element: <TaskList /> },
            { path: `${AppRoutes.plays}`, element: <LinkPlayer /> },
          ],
        },
        {
          path: `${AppRoutes.buckets}`,
          children: [
            { path: "", element: <BucketsList /> },
            { path: `${AppRoutes.bucket}`, element: <BucketEdit /> },
          ],
        },
        {
          path: `${AppRoutes.users}`,
          children: [
            { path: "", element: <UserList /> },
            { path: `${AppRoutes.user}`, element: <UserEdit /> },
          ],
        },
        
        {
          path: `${AppRoutes.languages}`,
          children: [
            { path: "", element: <LanguageList /> },
            { path: `${AppRoutes.language}`, element: <LanguageEdit /> },
          ],
        },
        {
          path: `${AppRoutes.packages}`,
          children: [
            { path: "", element: <PackageList /> },
            { path: `${AppRoutes.package}`, element: <PackageEdit /> },
          ],
        },
        {
          path: `${AppRoutes.password}`,
          children: [
            { path: "", element: <PasswordChange /> },
          ],
        },
        {
          path: `${AppRoutes.contents}`,
          children: [
            { path: "", element: <ContentList /> },
            { path: `${AppRoutes.content}`, element: <ContentEdit />},
            { path: `${AppRoutes.types}`, element: <ContentTypeList />},
            { path: `${AppRoutes.type}`, element: <ContentTypeEdit />},
            { path: `${AppRoutes.section}`, element: <ContentSectionEdit />},
            { path: `${AppRoutes.sections}`, element: <ContentSections /> },
            { path: `${AppRoutes.featured}`, element: <HomeContentEdit /> },
            { path: `${AppRoutes.languages}`, element: <LanguageList /> },
            { path: `${AppRoutes.language}`, element: <LanguageEdit/> },
          ],
        },
        {
          path: `${AppRoutes.appSettings}`,
          children: [
            { path: "", element: <AppSettings /> },
            { path: `${AppRoutes.music}`, element: <BackgroundMusic /> },
            { path: `${AppRoutes.backgroundMusics}`, element: <BackgroundMusics /> },
          ],
        },

        {
          path: `${AppRoutes.payments}`,
          children: [
            { path: "", element: <PaymentList /> },
            { path: `${AppRoutes.packages}`, element: <PackageList /> },
            { path: `${AppRoutes.package}`, element: <PackageEdit /> },
            { path: `${AppRoutes.payment}`, element: <PaymentEdit /> },
            { path: `${AppRoutes.trials}`, element: <FreeTrialEdit /> },
          ],
        },

        {
          path: ``,
          element: (
            <Navigate
              to={`${AppRoutes.dashboard}/${AppRoutes.newTask}${window.location.search}`}
            />
          ),
        },
      ],
    },
    {
      path: `${AppRoutes.auth}`,
      children: [
        { path: `${AppRoutes.login}`, element: <AuthLogin /> },
        {
          path: ``,
          element: (
            <Navigate
              to={`${AppRoutes.auth}/${AppRoutes.login}${window.location.search}`}
            />
          ),
        },
      ],
    },
    {
      path: "*",
      element: <Navigate to={`${AppRoutes.auth}/${AppRoutes.login}`} replace />,
    },
  ]);
}
