import { Grid } from "@mui/material";
import "react-dropzone-uploader/dist/styles.css";
import {
  guardRoute,
  isProduction,
} from "../utils/utils";
import EditPage from "./component/edit-page";
import EasyTextEdit from "./component/text-edit";


const CiCdInformation = () => {
  guardRoute(false, true);

  return (
    <EditPage
      title={"Jenkins Information"}
      status={{}}
      btnLabel={""}
      onSave={() => {}}
      validated={false}
      onFinish={() => {}}
    >
      <Grid item xs={5} xl={12}>
        <EasyTextEdit
          label="URL"
          placeholder="URL"
          type="text"
          id="url"
          errorText=""
          textField={true}
          disabled={true}
          value={isProduction() ? "https://ci.ubongo.org/" :"http://13.246.89.70:8080"}
          onChange={(_, __) => {}}
        />
      </Grid>

      <Grid item xs={3} xl={12}>
        <EasyTextEdit
          label="Username"
          placeholder="Username"
          type="text"
          id="name"
          errorText=""
          textField={true}
          disabled={true}
          value={"admin"}
          onChange={(_, __) => {}}
        />
      </Grid>

      <Grid item xs={4} xl={12}>
        <EasyTextEdit
          placeholder="Password"
          label="Password"
          type="text"
          textField={true}
          id="password"
          errorText=""
          disabled={true}
          value={"H{xW;ygEKPj.3R5S"}
          onChange={(_, __) => {}}
        />
      </Grid>
    </EditPage>
  );
};

export default CiCdInformation;