import { createSlice } from "@reduxjs/toolkit";
export const initialState = {
  instance: null,
};
const socketSlice = createSlice({
  name: "socket",
  initialState,
  reducers: {
    setSocket: (state, action) => {
      state.instance = action.payload;
    }
  },
});
export const { setSocket } = socketSlice.actions;
export default socketSlice.reducer;