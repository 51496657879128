import { Grid, Typography } from "@mui/material";
import "react-dropzone-uploader/dist/styles.css";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import {
  getParams,
  navigate,
  guardRoute,
  sendGetRequest,
  sendPostRequest,
  getFolderName,
  getDisplayFolderName,
  removeEmpty
} from "../utils/utils";
import EditPage from "./component/edit-page";
import { useState, useEffect } from "react";
import AppRoutes from "../routing/routes";
import EasyTextEdit from "./component/text-edit";
import {
  Dropzone, FileMosaic, FullScreen,
  VideoPreview
} from "@files-ui/react";
import {
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  InputAdornment,
  IconButton,
  Icon,
  DialogContent,
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  Breadcrumbs,
  Link,
  CircularProgress,
} from "@mui/material";
import { coreStyles } from "../theme/style";



const _ref = {
  uuid: "",
  source: "s3",
  location: null,
  url: ""
};

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));


const BackgroundMusic = () => {
  const appStyle = coreStyles();
  guardRoute(false, true);
  const { uuid } = getParams();
  var [setting, setSetting] = useState(_ref);
  var [urlError, setUrlError] = useState(false);
  var [files, setFiles] = useState([]);
  const [videoSrc, setVideoSrc] = useState(undefined);
  const [directories, setDirectories] = useState({});
  const [buckets, setBuckets] = useState([]);
  const [actives, setActives] = useState([]);
  const [navTrack, setNavTrack] = useState([]);
  const [browse, startBrowse] = useState(false);
  const [newFolder, setNewFolder] = useState("");

  const [status, setStatus] = useState({
    error: false,
    loading: false,
    message: null,
  });


  const updateFiles = (selectedFiles) => {
    const audio = [];
    selectedFiles.forEach(file => {
      if (file.type.startsWith("audio")) audio.push(file);
    });

    if (audio.length === 1) {
      setFiles(selectedFiles);
    }
  };

  const handleNavigate = (nav, top) => {
    if (status.loading) return;
    if (nav === "/") navTrack.splice(0, navTrack.length);
    if (!navTrack.includes(nav) && nav !== "/") navTrack.push(nav);

    const indexTrack = navTrack.indexOf(nav);
    if (indexTrack === -1) navTrack.splice(0, navTrack.length)
    if (indexTrack !== -1 && top) navTrack.splice(indexTrack + 1, navTrack.length)
    setNavTrack(navTrack);
    let _path = navTrack.slice(1, navTrack.length).join("/");
    _path = `${_path.length === 0 ? navTrack[0] ? navTrack[0] : "" : _path}/`;
    setNewFolder("");
    const dirsToShow = _path === "/" ? buckets.map(buck => `${buck.name}/`) : directories[_path];
    setActives(dirsToShow);
  }

  const isBucketSeletions = () => navTrack.length === 0;

  useEffect(() => {
    setStatus({ ...{ loading: true } })
    Promise.all([
      sendGetRequest(`directories`),
      sendGetRequest(`buckets`),
    ]).then(result => {
      setStatus({ ...{ loading: false } })
      setDirectories(result[0])
      setBuckets(result[1])
    });
    return () => setSetting(_ref);
    // eslint-disable-next-line
  }, [uuid]);

  
  const getContent = () => {
    if (setting.location) setting.location = getPath();
   const formData = new FormData();
    const _setting = removeEmpty(setting);
    for (const [key, value] of Object.entries({ ..._setting })) {
      formData.append(key, value);
    }
    if (files.length === 1) {
      files.forEach(file => {
        formData.append('files', file.file)
      })
    }
    return {
      form: formData
    };
  }


  const getPath = (withBucket = true) => withBucket === true ? `s3://${navTrack.join("/")}` : navTrack.slice(1, navTrack.length).join("/")

  const createDirectory = () => {
    sendPostRequest('directories', { name: `${getPath()}/${newFolder}/` },
      (loading) => setStatus({ ...{ loading: true } }), (data) => {
        setDirectories(data.data);
      });
  }


  useEffect(() => {
    if (newFolder.length > 1) {
      setStatus({ ...{ loading: false } })
      handleNavigate(navTrack[navTrack.length - 1], true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [directories]);

  const showUrlOps = () => setting.source === "s3";

  const onValueChange = (value, tag) => {
    setSetting({ ...setting, [tag]: value });
    const error = !(`${value}`.startsWith("s3://") && `${value}`.includes(".") && `${value}`.split(".").length >= 2);
    if (tag === "url") setUrlError(error);

  };

  const onDelete = (id) => {
    setFiles(files.filter((_file) => _file.id !== id));
  };

  const handleWatch = (videoSource) => {
    setVideoSrc(videoSource);
  };


  const checkPropsValidity = () => {
    const isS3Validsource = (setting.source === "s3" && !files.length) && !urlError;
    const isFileValidSource = files.length && setting.source !== "s3" && setting.location !== null;
    return isS3Validsource || isFileValidSource;
  }

  const createUpdate = () => {
    sendPostRequest('music', getContent().form,
      (loading) => setStatus({ ...{ loading: loading } }), (data) => {
        setStatus({
          ...{
            error: data.error,
            message: data.message,
          },
        });
      });
  }

  return (
    <EditPage
      title={"Background Music"}
      status={status}
      btnLabel={"Add Now"}
      onSave={() => createUpdate()}
      spacing={5}
      onBack={() => navigate(`${AppRoutes.appSettings}/${AppRoutes.backgroundMusics}`, true)}
      validated={checkPropsValidity()}
      onFinish={() => {
        setStatus({ ...{ loading: false, message: null, error: false } });
        if (!status.error) {
          setSetting({ ..._ref });
          navigate(`${AppRoutes.appSettings}/${AppRoutes.backgroundMusics}`, true);
        }
      }}
    >
      <Grid item xs={showUrlOps() ? 2: 4} xl={12}>
        <FormControl sx={{ minWidth: 50 }} fullWidth>
          <InputLabel id="content-source">Music Source</InputLabel>
          <Select
            value={setting.source || "s3"}
            label="Music Source"
            labelId="content-source"
            id="source"
            disabled={status.loading}
            onChange={(e) => onValueChange(e.target.value, "source")}
          >
            {[
              { name: "S3 URLS", value: "s3" },
              { name: "File Upload", value: "file" },
            ].map((souces) => {
              return (
                <MenuItem value={souces.value} id={souces.value}>
                  &nbsp;&nbsp;&nbsp;{souces.name}
                  <br />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      
      {showUrlOps() && (
        <Grid item xs={6} xl={12}>
          <EasyTextEdit
            placeholder="S3 file URI i.e s3://bucket-id/folder/directory/audio.mp3"
            label="Actual music content url (Audio)"
            type="text"
            textField={true}
            error={urlError}
            id="url"
            errorText={urlError ? "Make sure you fill in AWS s3 file URI" : ""}
            disabled={status.loading}
            value={setting.url}
            onChange={onValueChange}
          />
        </Grid>
      )}

      <Grid item xs={showUrlOps()  ? 4 : 8} xl={12}>
        <EasyTextEdit
          label={`Save output to (${setting.source === "file" ? "Mandatory" : "Optional"})`}
          placeholder="New location when your content will be available"
          type="text"
          id="location"
          errorText=""
          error={false}
          textField={true}
          value={setting.location}
          onChange={(value, field) => null}
          disabled={status.loading}
          endAdornment={<InputAdornment
            onClick={() => {
              if (!status.loading) {
                handleNavigate("/", false);
                startBrowse(true);
              }

            }}
            position="end"
          >
            <IconButton edge="center">
              <Icon
                children={"folder_open"}
                className={appStyle.input_icons}
              />
            </IconButton>
          </InputAdornment>}
          startAdornment={setting.location && (<InputAdornment
            onClick={() => onValueChange(undefined, "location")}
            position="start"
          >
            <IconButton edge="center">
              <Icon
                children={"delete"}
                className={appStyle.input_icons}
              />
            </IconButton>
          </InputAdornment>)}
        />
      </Grid>

      {setting.source === "file" && (
        <Grid item xs={12} xl={12}>
          <Dropzone
            onChange={updateFiles}
            value={files}
            minHeight="195px"
            maxFiles={1}
            accept={"audio/*"}
            maxFileSize={1024 * 1024 * 5}
            label="Drag'n drop audio here or click to browse"
          >
            {files.map((file) => (
              <FileMosaic
                {...file}
                resultOnTooltip
                alwaysActive
                preview
                info
                onDelete={onDelete}
                onWatch={handleWatch}
              />
            ))}
          </Dropzone>

          <FullScreen
            open={videoSrc !== undefined}
            onClose={() => setVideoSrc(undefined)}
          >
            <VideoPreview src={videoSrc} autoPlay controls />
          </FullScreen>
        </Grid>
      )}

      
      <Dialog
        open={browse}
        onClose={() => startBrowse(false)}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle> {isBucketSeletions() ? `Select a source to save from` : `Where do you want to save your files`}</DialogTitle>
        <DialogContent>
          <Grid xs={12} xl={12}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              {["/", ...navTrack].map((nav, index) => (
                <Link
                  underline="hover"
                  key={`${index + 1}`}
                  color="inherit" href="/"
                  onClick={(event) => {
                    event.preventDefault();
                    handleNavigate(nav, true);
                  }}>
                  {getDisplayFolderName(nav)}
                </Link>
              ))}
            </Breadcrumbs>
          </Grid>
          <Grid container direction="row">

            {(actives || []).length > 0 && (actives.map(dir => {
              const full = directories[dir] && directories[dir].length > 0;
              return (<Grid sx={{ mt: 4 }} spacing={isBucketSeletions() ? (actives.length === 1 ? 12 : 6) : 3} xs={isBucketSeletions() ? 6 : 4} xl={12} className={appStyle.folder}>
                
                <HtmlTooltip title={getFolderName(dir)}>
                  <IconButton
                    edge="center"
                    onClick={() => {
                      handleNavigate(getFolderName(dir, false), false);
                    }}
                  >
                    <Icon
                      children={isBucketSeletions() ? "source" : full ? "folder" : "folder_open"}
                      className={full ? appStyle.folder_icons_can : appStyle.folder_icons}
                    />
                  </IconButton>
                <Typography
                  color="text.primary" align="center" mt={1}>{getDisplayFolderName(getFolderName(dir))}</Typography>
                {full && (<Typography
                  color="text.disabled" variant="caption" align="center" >{`${directories[dir].length} sub director${directories[dir].length === 1 ? "y" : "ies"}`}</Typography>)}
                </HtmlTooltip>
                </Grid>)
            }))}
          </Grid>

          {!isBucketSeletions() && (<Grid container sx={{ pt: 8, mb: 4 }} direction="row">
            <Grid item xs={7} xl={12} >
              <EasyTextEdit
                label="New directory name"
                placeholder="Content's directory name"
                type="text"
                id="directory"
                errorText=""
                textField={true}
                disabled={status.loading}
                value={newFolder}
                onChange={(value) => setNewFolder(value)}
              />
            </Grid>
            <Grid item xs={4} xl={12} justifyContent="center">  <Button
              variant="contained"
              className={appStyle.createDirectory}
              sx={{ ml: 2 }}
              fullWidth
              disabled={status.loading || newFolder.length <= 0}
              onClick={(event) => createDirectory()}
            >{status.loading && <CircularProgress color="primary" />}{status.loading ? "" : "Create directory"}</Button> </Grid>

          </Grid>)}
        </DialogContent>
        <DialogActions sx={{ pb: 4 }}>
          <Button sx={{ mr: 2, pl: 2, pr: 2 }} onClick={() => {
            handleNavigate("/", false);
            startBrowse(false);
          }}>Cancel</Button>
          {!isBucketSeletions() && (<Button sx={{ mr: 2, pl: 2, pr: 2 }} onClick={() => {
            onValueChange(getPath(false), "location")
            startBrowse(false);
          }}>Select</Button>)}

        </DialogActions>
      </Dialog>
    </EditPage>
  );
};

export default BackgroundMusic;