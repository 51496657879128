import { Grid, Typography } from "@mui/material";
import "react-dropzone-uploader/dist/styles.css";
import {
  getParams,
  navigate,
  guardRoute,
  sendPostRequest,
  sendGetRequest,
  sendPatchRequest,
} from "../utils/utils";
import EditPage from "./component/edit-page";
import { useState, useEffect } from "react";
import AppRoutes from "../routing/routes";
import EasyTextEdit from "./component/text-edit";

const _ref = {
  name: "",
  description: "",
  trademark: {
    width: 200,
    height: 106,
    imageX: 50,
    imageY: 30,
    fadein: 1000,
    fadeout: null,
    opacity: 100,
    startat: 5,
    image: "",
  },
  token: null,
  intro: "",
  outro: "",
  code: ""
};

const ChannelEdit = () => {
  guardRoute(false, true);
  const { uuid } = getParams();
  var [channel, setChannel] = useState(_ref);

  const [status, setStatus] = useState({
    error: false,
    loading: false,
    message: null,
  });
  useEffect(() => {
    sendGetRequest(`channels?uuid=${uuid}`,
      (loading) => setStatus({ ...{ loading: loading } }),
      (data) => {
        if (data) {
          setChannel({ ..._ref, ...data });
        }
      })
    return () => setChannel(_ref);
    // eslint-disable-next-line
  }, [uuid]);

  const createUpdateChannel = () => {
    if (uuid) {
      sendPatchRequest(`channels/${uuid}`, channel,
        (loading) => setStatus({ ...{ loading: loading } }), (data) => {
          setStatus({
            ...{
              error: data.error,
              message: data.message,
            },
          });
        })
    } else {
      sendPostRequest('channels', channel,
        (loading) => setStatus({ ...{ loading: loading } }), (data) => {
          setStatus({
            ...{
              error: data.error,
              message: data.message,
            },
          });
        })
    }
  };

  const onValueChange = (value, tag) => {
    if (tag.includes("mark_")) {
      channel.trademark[`${tag}`.replace("mark_","")] = value;
    } else {
      channel[tag] = value;
    }
    setChannel({ ...channel });
  };

  return (
    <EditPage
      title={uuid ? "Edit Channel" : "Add Channel"}
      status={status}
      btnLabel={uuid ? "Update Now" : "Add Now"}
      onSave={() => createUpdateChannel()}
      onBack={() => navigate(`${AppRoutes.channels}`, true)}
      validated={
        channel.name &&
        channel.intro &&
        channel.outro &&
        channel.code &&
        channel.description &&
        channel.trademark.image &&
        channel.trademark.imageX &&
        channel.trademark.imageY &&
        channel.trademark.width &&
        channel.trademark.height &&
        channel.trademark.fadein &&
        channel.trademark.opacity &&
        channel.trademark.startat &&
        !status.loading
      }
      onFinish={() => {
        setStatus({ ...{ loading: false, message: null, error: false } });
        if (!status.error) {
          setChannel({ ..._ref });
          navigate(`${AppRoutes.channels}`, true);
        }
      }}
    >
      <Grid item xs={7} xl={12}>
        <EasyTextEdit
          placeholder="Channel Name"
          type="text"
          id="name"
          errorText=""
          textField={true}
          disabled={status.loading}
          value={channel.name}
          onChange={onValueChange}
        />
      </Grid>

      <Grid item xs={5} xl={12}>
        <EasyTextEdit
          placeholder="Mostly used shortform i.e akiliandme"
          label="Channel code"
          type="text"
          id="code"
          errorText=""
          textField={true}
          disabled={status.loading}
          value={channel.code}
          onChange={onValueChange}
        />
      </Grid>

      <Grid item xs={6} xl={12}>
        <EasyTextEdit
          placeholder="Channel Intro Clip"
          type="text"
          textField={true}
          id="intro"
          errorText=""
          disabled={status.loading}
          value={channel.intro}
          onChange={onValueChange}
        />
      </Grid>
      <Grid item xs={6} xl={12}>
        <EasyTextEdit
          placeholder="Channel Outro Clip"
          type="text"
          id="outro"
          textField={true}
          errorText=""
          disabled={status.loading}
          value={channel.outro}
          onChange={onValueChange}
        />
      </Grid>
      <Grid item xs={12}>
        <EasyTextEdit
          label="Youtube video description"
          placeholder="Default description that will be used for all videos uploaded to this channel"
          type="text"
          id="description"
          textField={true}
          errorText=""
          multiline={true}
          disabled={status.loading}
          value={channel.description}
          onChange={onValueChange}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" component="h2">
          Trademark properties{" "}
        </Typography>
      </Grid>
      <Grid item xs={6} xl={12}>
        <EasyTextEdit
          placeholder="Trademark Image"
          type="text"
          textField={true}
          id="mark_image"
          errorText=""
          disabled={status.loading}
          value={channel.trademark.image}
          onChange={onValueChange}
        />
      </Grid>
      <Grid item xs={3} xl={4}>
        <EasyTextEdit
          placeholder="Trademark Width"
          type="text"
          textField={true}
          id="mark_width"
          errorText=""
          disabled={status.loading}
          value={channel.trademark.width}
          onChange={onValueChange}
        />
      </Grid>
      <Grid item xs={3} xl={4}>
        <EasyTextEdit
          placeholder="Trademark Height"
          type="text"
          id="mark_height"
          errorText=""
          textField={true}
          disabled={status.loading}
          value={channel.trademark.height}
          onChange={onValueChange}
        />
      </Grid>

      <Grid item xs={4} xl={6}>
        <EasyTextEdit
          placeholder="Trademark Opacity"
          type="text"
          id="mark_opacity"
          errorText=""
          textField={true}
          disabled={status.loading}
          value={channel.trademark.opacity}
          onChange={onValueChange}
        />
      </Grid>

      <Grid item xs={4} xl={6}>
        <EasyTextEdit
          placeholder="Trademark Left"
          type="text"
          textField={true}
          id="mark_imageX"
          errorText=""
          disabled={status.loading}
          value={channel.trademark.imageX}
          onChange={onValueChange}
        />
      </Grid>

      <Grid item xs={4} xl={6}>
        <EasyTextEdit
          placeholder="Trademark Top"
          type="text"
          textField={true}
          id="mark_imageY"
          errorText=""
          disabled={status.loading}
          value={channel.trademark.imageY}
          onChange={onValueChange}
        />
      </Grid>
      <Grid item xs={4} xl={6}>
        <EasyTextEdit
          placeholder="Trademark FadeIn (Ms)"
          type="text"
          id="mark_fadein"
          errorText=""
          textField={true}
          disabled={status.loading}
          value={channel.trademark.fadein}
          onChange={onValueChange}
        />
      </Grid>

      <Grid item xs={4} xl={6}>
        <EasyTextEdit
          placeholder="Trademark FadeOut (Ms)"
          type="text"
          id="mark_fadeout"
          errorText=""
          textField={true}
          disabled={status.loading}
          value={channel.trademark.fadeout}
          onChange={onValueChange}
        />
      </Grid>

      <Grid item xs={4} xl={6}>
        <EasyTextEdit
          placeholder="Trademark Start At (Ms)"
          type="text"
          id="mark_startat"
          errorText=""
          textField={true}
          disabled={status.loading}
          value={channel.trademark.startat}
          onChange={onValueChange}
        />
      </Grid>
    </EditPage>
  );
};

export default ChannelEdit;