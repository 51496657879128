import { useState } from "react";
import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Snackbar, SnackbarContent } from "@mui/material";
import { useSelector } from "react-redux";
import { guardRoute, InitSessionObserver, showSnack } from "../utils/utils";
import DashboardNavbar from "./component/@navs/navbar";
import DashboardSidebar from "./component/@navs/sidebar";
import { coreStyles } from "../theme/style";

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export default function Dashboard() {
  InitSessionObserver();
  guardRoute();
  const [open, setOpen] = useState(false);
  const { snack } = useSelector((state) => state.snackbar);
  const appStyle = coreStyles();

  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
      />
      <MainStyle>
        {snack && snack.message && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            onClose={() => {
              showSnack(null);
            }}
            open={snack.message && snack.message.length > 0}
            autoHideDuration={5000}
            message={snack.message}
          >
            <SnackbarContent
              className={appStyle.snackbar}
              message={<span id="client-snackbar">{snack.message}</span>}
            />
          </Snackbar>
        )}
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
