import makeStyles from "@mui/styles/makeStyles";

export const coreStyles = makeStyles((theme) => ({
  container: {
    height: "70vh",
    margin: "0px !important",
    padding: `${theme.spacing(2)} 0px 0px 0px !important`,
    backgroundColor: `transparent`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  title: {
    fontWeight: "900 !important",
  },
  loginButton: {
    fontSize: "1.1rem",
    marginTop: `${theme.spacing(2.5)} !important`,
    marginBottom: `${theme.spacing(3)} !important`,
    padding: theme.spacing(1.5),
  },

  createDirectory: {
    fontSize: "1rem",
    padding: `${theme.spacing(1.5)} ${theme.spacing(4)} !important`,
  },
  policyLink: {
    fontSize: "1rem",
    textDecoration: "none",
    textAlign: "center !important",
    marginLeft: `40% !important`,
    fontWeight: "600 !important",
  },
  innerButton: {
    fontSize: "1.1rem",
    marginTop: `${theme.spacing(6)} !important`,
    padding: theme.spacing(1.5),
  },
  payButton: {
    fontSize: "1.1rem",
    marginTop: `${theme.spacing(2)} !important`,
    padding: theme.spacing(1.5),
  },
  button: {
    fontSize: "1.1rem",
    padding: theme.spacing(1.5),
  },
  circular: {
    fontSize: "0.4em !important",
  },
  menuIcon: {
    fontSize: "2rem !important",
  },
  actionButon: {
    fontSize: "1rem !important",
    padding: `${theme.spacing(1)} ${theme.spacing(2)} !important`,
  },
    actionButonCustom: {
    fontSize: "1rem !important",
    padding: `${theme.spacing(1)} ${theme.spacing(2)} !important`,
    marginLeft:`${theme.spacing(3)} !important`
  },
  actionButonIcon: {
    fontSize: "1.8rem !important",
  },
  loginCard: {
    marginBlock: theme.spacing(4),
    padding: "8% !important",
  },
  loginWrapper: {
    margin: "5% !important",
  },
  asLink:{
    cursor: "pointer"
  },
  links: {
    textDecoration: "none !important",
    margin: `${theme.spacing(2)} 0px !important`,
    fontSize: "1.1em !important",
    fontWeight: "700 !important",
    alignItems: "center !important",
  },
  link_span: {
    fontSize: "1.1em !important",
    textDecoration: "none !important",
    alignItems: "center !important",
  },
  sub_title: {
    textDecoration: "none !important",
    padding: "0 10% !important",
  },
  textPadding: {
    padding: "0 10% !important",
  },
  inputs: {
    padding: "0.1em !important",
    width: "100% !important",
  },
  inputsNoPadding: {
    fontSize: "1.2em !important",
    width: "100% !important",
  },
  input_icons: {
    fontSize: "1.2em !important",
  },
  folder_icons: {
    fontSize: "3em !important",
  },
  folder_icons_can: {
    fontSize: "3em !important",
    color: "text.disabled"
  },
  folder: {
    justifyContent:"center !important",
    alignItems: "center !important",
    textAlign: "center !important",
  },
  hide: {
    display: "none !important",
  },
  show: {
    display: "flex column !important",
  },
  icon_standalone: {
    fontSize: "6rem !important",
  },
  icon_empty: {
    fontSize: "10rem !important",
  },
  avatarMargin: {
    marginTop: `${theme.spacing(1)} !important`,
    marginLeft: `${theme.spacing(1)} !important`,
  },
  paddingRow: {
    paddingLeft: "2.8% !important",
  },
  checkboxPadding: {
    padding: `0px 0px 0px ${theme.spacing(2)} !important`,
  },
  snackbar: {
    fontWeight: 400,
    fontSize: "1.1em !important",
    padding: `${theme.spacing(2)} ${theme.spacing(3)} !important`,
  },
  selectedGrouped: {
    backgroundColor: `${theme.palette.primary.lighter} !important`,
  },
  balance: {
    fontWeight: "400 !important",
  },
  tableText: {
    fontSize: "10px !important",
    lineHeight: "0.25px !important",
    fontWeight: "normal !important",
    letterSpacing: "0.01em !important",
  },
  userName: {
    fontWeight: 200,
  },
  description: {
    fontWeight: "1.2em !important",
  },
  zeroPadding: {},
  uploadButton: {
    fontSize: "1.1rem",
    marginTop: `${theme.spacing(0.5)} !important`,
    padding: `${theme.spacing(1.5)} ${theme.spacing(2.5)}`,
    marginBottom: `${theme.spacing(2)} !important`,
  },
  selectTableCell: {
    width: 60,
  },
  tableCell: {
    width: "500px !important",
    height: 40,
  },
  tableCellSelectField: {
    width: "150px !important",
  },
  input: {
    width: 130,
    height: 40,
  },
  preview_margin: {
    marginLeft: "230px !important",
  },
  preview_image: {
    width: "283px !important",
    marginTop: "3px !important"
  },
  infoText: {
    fontSize:".9em !important",
    color: `${theme.palette.primary.main} !important`,
  },
  authText: {
    fontSize: "1.2em !important",
    color: `${theme.palette.primary.main} !important`,
  },
  highlightText: {
    fontSize: ".9em !important",
    color: `${theme.palette.text.main} !important`,
  },
}));
