import {
  getActiveUser,
  navigate,
  guardRoute,
  sendGetRequest,
  purpose,
  UserRoles,
  formatDate,
  sendDeleteRequest,
  watchLiveData,
} from "../utils/utils";
import {
  Button,
  Tooltip,

} from "@mui/material";
import DataListWithSearch from "./component/@list/list-search";
import { useState, useEffect } from "react";
import AppRoutes from "../routing/routes";
import Label from "./component/label";
import { ageGroup, getAgeRanges } from "./game-edit";

const getTableHead = () => {
  return [
    { id: "created", label: "Created On", alignRight: false },
    { id: "title", label: "Title", alignRight: false },
    { id: "premium", label: "Premium", alignRight: false },
    { id: "ranges", label: "Audiance", alignRight: false },
  ];
};

export const GameList = () => {
  guardRoute();
  const loggedUser = getActiveUser();
  const [dataList, setDataList] = useState([]);
  const [loading, onLoading] = useState(false);
  const [filterKey, setFilterkey] = useState("title");

  const userOptions = [
    {
      icon: "delete",
      value: "delete",
      label: "Delete",
      onClick: (value, selected) => {
        onLoading(true);
        Promise.all(
          selected.map((uuid) => sendDeleteRequest("games", uuid))
        ).then((response) => {
          executeFetch();
        });
      },
    },
    
    
    {
      icon: "edit",
      value: "edit",
      label: "Edit",
      onClick: (_value, selected) => startEdit(selected),
    },
  ];

  const startEdit = (uuid) => navigate(`${AppRoutes.games}/${AppRoutes.game}${uuid ? `?uuid=${uuid}` : ''}`, true);

  const filterOptions = [
    { value: "title", label: "Title", onClick: setFilterkey },
  ];

  const executeFetch = (refresh = false) => {
    Promise.all([
      sendGetRequest('games?metadata=true', (loading) => onLoading(refresh ? false : loading),
        (data) => setDataList(data)),
      ])
  };


  useEffect(() => {
    executeFetch()
    watchLiveData("games", (data) => executeFetch(true));
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <DataListWithSearch
        sectionTitle="Games"
        createNewLabel={loggedUser.active ? "Add Game" : null}
        data={dataList}
        primaryKey="uuid"
        filterColumn={filterKey}
        emptyIcon="toc"
        avatarKey="uuid"
        loading={loading}
        onCreateNewItem={() => startEdit()}
        headers={getTableHead()}
        rowData={(data) => {
          const isPremium = data.premium && data.premium.length > 0;
          const label = purpose(isPremium);
          const ranges = getAgeRanges(data.fromAge, data.toAge);
          const _entries = [
            { value: formatDate(data.created) },
            { value: data.title, width: 160 },
            {
              ...(isPremium ? {
                value: (
                  <Tooltip title={data.premium.map(pre => pre.name).join(", ")} arrow>
                    <Button color="success">
                      <Label variant="ghost" color={label.color}>
                        {label.value}
                      </Label>
                    </Button>
                  </Tooltip>)
              } : {
                value: (<Button color="error">
                  <Label variant="ghost" color={label.color}>
                    {label.value}
                  </Label>
                </Button>)
              })
            },
            { value: ageGroup.filter(group => ranges.includes(group.range)).map(range => range.name).join(", ") },
          ];
          return {
            exclude: [
              data.premium === null ? "free" : "premium",
              loggedUser.role === UserRoles.editor ? "delete" : "",
              ],
            entries: _entries,
          };
        }}
        selectionOptions={userOptions.filter((option) =>
          (option.value !== "edit" && option.value !== "preview") || (loggedUser.role === UserRoles.editor ? option.value !== "delete" : false))}
        filterOptions={filterOptions}
        optionItems={userOptions}
      />
    </div>
  );
};