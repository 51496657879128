import {
  CircularProgress,
  Button,
  Card,
  Grid,
  Box,
  Typography,
} from "@mui/material";
import { coreStyles } from "../theme/style";
import { useEffect, useState } from "react";
import EasyTextEdit from "./component/text-edit";
import {
  guardRoute,
  loggedInitSession,
  isError,
  validateEmail,
  validateFields,
  sendPostRequest,
} from "../utils/utils";
import Status from "./component/status";

const label = "Sign In";

const validationRef = {
  email: { error: true, touched: false},
  password: { error: true, touched: false }
}

const authPropsRef = {
  email: "",
  password: ""
}

const AuthLogin = () => {
  guardRoute();
  const appStyle = coreStyles();
  const [onAuthing, setOnAuthing] = useState(false);
  const [btnLabel, setLabel] = useState(label);
  const [validation, setValidation] = useState(validationRef);
  const [validated, setValidated] = useState(false);
  const [authProps] = useState(authPropsRef);
  const [status, setStatus] = useState({ error: false, message: null });

  const handleSubmit = (event) => {
    event.preventDefault();
    setLabel("");
    sendPostRequest('users/auth', 
    {username: authProps.email, password: authProps.password}, (loading) => setOnAuthing(loading), (result) => {
      setLabel(label);
      if (result.error) {
        setStatus({
          ...{
            error: result.error,
            message: result.message,
          },
        });
      }
      if (result) loggedInitSession(result);
    })
  };

  const onValueChanged = (value, isEmail) => {
    authProps[isEmail ? "email":"password"] = value;
    if (isEmail) {
      validation.email = { error: !validateEmail(value), touched: true };
    }
    if (!isEmail) {
      validation.password = { error: value.length < 6, touched: true };
    }
    setValidation({ ...validation });
    setValidated(validateFields(validation));
  };

  useEffect(() => {
    setValidated(validateFields(validation));
    // eslint-disable-next-line
  }, [authProps.email, authProps.password]);

  return (
    <Grid
      container
      className={appStyle.container}
      justifyContent="center"
      elevation={4}
      alignItems="center"
    >
      <Grid item xs={12} md={5} mt={8}>
        <Card className={appStyle.loginCard}>
          <Typography
            variant="h2"
            color="primary.darker"
            align="center"
            mb={4}
            className={appStyle.title}
          >
            Welcome Back
          </Typography>
          <Box noValidate>
            {status && status.message && (
              <Grid item xs={12} mb={4}>
                <Status
                  error={status.error}
                  timeout={5000}
                  message={status.message}
                  onClose={() => {
                    if (status.error) {
                      setStatus({ ...{ message: null } });
                    }
                  }}
                />
              </Grid>
            )}

            <Grid container justifyContent="center" spacing={3}>
              <Grid item xs={12}>
                <EasyTextEdit
                  placeholder="Email address"
                  type="email"
                  id="email"
                  value={authProps.email}
                  errorText="Invalid email address"
                  error={isError(validation.email)}
                  disabled={onAuthing}
                  icon="alternate_email"
                  onChange={(value, field) => onValueChanged(value, true)}
                />
              </Grid>

              <Grid item xs={12}>
                <EasyTextEdit
                  placeholder="Password"
                  type="password"
                  id="password"
                  errorText={`Invalid password format`}
                  error={isError(validation.password)}
                  disabled={onAuthing}
                  icon="password"
                  value={authProps.password}
                  password={true}
                  onChange={(value, field) => onValueChanged(value, false)}
                />
              </Grid>
            </Grid>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              onClick={handleSubmit}
              disabled={!(validated && !onAuthing)}
              className={appStyle.loginButton}
            >
              {onAuthing && <CircularProgress color="primary" />}
              {btnLabel}
            </Button>

            <a href="https://www.ubongo.org/privacy-policy/upload-ubongo-privacy-policy/" traget="_blank" className={appStyle.policyLink} color="primary.darker">Our Privacy Policies</a>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};

export default AuthLogin;
