import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Toolbar, Typography } from '@mui/material';
import MoreMenu from './more';
import { MuiChipsInput } from 'mui-chips-input';
import { useState } from "react";

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1, 0, 3),
}));

const FilterStyle = styled(MuiChipsInput)(({ theme }) => ({
  width: "100%",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": { width: "100%", boxShadow: theme.customShadows.z8 },
  "& input": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

ListToolbar.propTypes = {
  selected: PropTypes.array,
  filterName: PropTypes.string,
  label: PropTypes.string,
  selectionOptions: PropTypes.array,
  onOptionSelection: PropTypes.func.isRequired,
  filterOptions: PropTypes.array,
  onFilterName: PropTypes.func,
  onFilterChange: PropTypes.func
};

export default function ListToolbar({
  selected = [], 
  filterName,
  label,
  selectionOptions,
  onOptionSelection,
  filterOptions,
  onFilterName,
  onFilterChange
}) {
  const selection = selected.length > 0 && selectionOptions;
  const [chips, setChips] = useState([]);
  const handleChange = (newChips) => {
    setChips(newChips);
    onFilterChange(newChips);
  }
  return (
    <RootStyle
      sx={{
        ...(selected.length > 0 && {
          color: "primary.main",
          bgcolor: "primary.lighter",
        }),
      }}
    >
      {selected.length > 0 ? (
        <Typography component="h3" variant="title1">
          {selected.length} video{selected.length > 1 ? "s" : ""} selected
        </Typography>
      ) : (<FilterStyle
          value={chips}
          sx={{border:"2px solid primary"}}
          placeholder="Type and press enter to filter videos"
          onChange={handleChange}
        />)}
      {(selection) && (
        <MoreMenu
          selected={selected}
          icon={selection ? "more_vert" : "filter_list"}
          onItemSelected={() => onOptionSelection()}
          options={selection ? selectionOptions : filterOptions}
        />
      ) }
    </RootStyle>
  );
}