import { Grid } from "@mui/material";
import "react-dropzone-uploader/dist/styles.css";
import {
  getParams,
  navigate,
  guardRoute,
  sendGetRequest,
  sendPostRequest,
  sendPatchRequest,
} from "../utils/utils";
import EditPage from "./component/edit-page";
import { useState, useEffect } from "react";
import AppRoutes from "../routing/routes";
import EasyTextEdit from "./component/text-edit";

const _ref = {
  uuid: "",
  activeIcon: "",
  inactiveIcon: "",
  order: "",
  name: "",
  active: true
};

const ContentTypeEdit = () => {
  guardRoute(false, true);
  const { uuid } = getParams();
  var [category, setCategory] = useState(_ref);

  const [status, setStatus] = useState({
    error: false,
    loading: false,
    message: null,
  });
  useEffect(() => {
    sendGetRequest(`categories?uuid=${uuid}`,
      (loading) => setStatus({ ...{ loading: loading } }), (data) => setCategory(data))
    return () => setCategory(_ref);
    // eslint-disable-next-line
  }, [uuid]);

  const createUpdate = () => {
    if (!uuid) {
      delete category.uuid;
      sendPostRequest('categories', {...category, active: true},
        (loading) => setStatus({ ...{ loading: loading } }), (data) => {
          setStatus({
            ...{
              error: data.error,
              message: data.message,
            },
          });
        });
    } else {
      category.uuid = uuid;
      sendPatchRequest(
        `categories/${uuid}`,
        category,
        (loading) => setStatus({ ...{ loading: loading } }),
        (data) => {
          setStatus({
            ...{
              error: data.error,
              message: data.message,
            },
          });
        }
      );
    }
  };

  const onValueChange = (value, tag) => {
    setCategory({ ...category, [tag]: value });
  };

  return (
    <EditPage
      title={uuid ? "Edit content type" : "Add content type"}
      status={status}
      btnLabel={uuid ? "Update Now" : "Add Now"}
      onSave={() => createUpdate()}
      validated={category.name && category.order && !status.loading}
      onBack={() =>navigate(`${AppRoutes.contents}/${AppRoutes.types}`, true)}
      onFinish={() => {
        setStatus({ ...{ loading: false, message: null, error: false } });
        if (!status.error) {
          setCategory({ ..._ref });
          navigate(`${AppRoutes.contents}/${AppRoutes.types}`, true);
        }
      }}
    >
      <Grid item xs={4} xl={12}>
        <EasyTextEdit
          label="Content type"
          placeholder="Name of the type"
          type="text"
          id="name"
          errorText=""
          textField={true}
          disabled={status.loading}
          value={category.name}
          onChange={onValueChange}
        />
      </Grid>

      <Grid item xs={3} xl={12}>
        <EasyTextEdit
          placeholder="Asset file name"
          label="Active Icon"
          type="text"
          textField={true}
          id="activeIcon"
          errorText=""
          disabled={status.loading}
          value={category.activeIcon}
          onChange={onValueChange}
        />
      </Grid>
      <Grid item xs={3} xl={12}>
        <EasyTextEdit
          placeholder="Asset file name"
          label="Inactive Icon"
          type="text"
          id="inactiveIcon"
          textField={true}
          errorText=""
          disabled={status.loading}
          value={category.inactiveIcon}
          onChange={onValueChange}
        />
      </Grid>

      <Grid item xs={2} xl={12}>
        <EasyTextEdit
          placeholder="Ordering preference"
          label="Order"
          type="number"
          id="order"
          textField={true}
          errorText=""
          disabled={status.loading}
          value={category.order}
          onChange={onValueChange}
        />
      </Grid>
    </EditPage>
  );
};

export default ContentTypeEdit;